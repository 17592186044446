import ReactGA from "react-ga4";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LinearProgress from "@mui/material/LinearProgress";
import {useNavigate} from "react-router-dom";
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';

import ReactMarkdown from 'react-markdown';


import {paths} from "../../routes/paths";
import {useAuthContext} from "../../auth/hooks";
import axios, {endpoints} from "../../utils/axios";
import {AIAnalysisType, AIAnalysisTypesEnum} from "./types";
import {getUserType, UserTypes} from "./utils";
import GeneratedOn from "../utility_components/generated-on";


export default function AIAnalysis({ticker, analysisType, setAiAnalysesReady}: {
  ticker: string | undefined,
  analysisType: AIAnalysisTypesEnum,
  setAiAnalysesReady?: (key: string) => void | undefined;
}) {
  const {authenticated, user} = useAuthContext();
  const [aiAnalysis, setAiAnalysis] = useState<AIAnalysisType | null>(null);
  const [isLoadingAnalysisLatest, setIsLoadingAnalysisLatest] = useState(false);
  const [analysisDatetimeGenerated, setAnalysisDatetimeGenerated] = useState<string>('');

  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const userType = getUserType(user, ticker);


  useEffect(() => {
    const regenerateAiAnalysis = async () => {
      try {
        setAiAnalysis(null);
        setIsLoadingAnalysisLatest(true);
        const result = await axios.post<any>(
          `${endpoints.ai_analysis}/${ticker}?analysis_type=${analysisType}&return_latest=true`,
        );
        setAiAnalysis(result.data);
        setAnalysisDatetimeGenerated(result.data.datetime_generated);
        setIsLoadingAnalysisLatest(false);
      } catch (error) {
        console.error("Error fetching AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady(analysisType);
        }
      }
    }

    if (ticker && authenticated && (userType === UserTypes.PremiumWithCredits || userType === UserTypes.FreeWithCredits)) {
      regenerateAiAnalysis()
    }
  }, [ticker, authenticated, user, userType, analysisType, setAiAnalysesReady]);

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  const handleSignUpClick = () => {
    ReactGA.event("AI SignUpClick", {
      category: "New Feature Sign up",
      action: "Click",
      label: "AI Analysis"
    });
  }
  return (
    <Box>
      <Button variant="outlined" color="primary" endIcon={<TipsAndUpdatesTwoToneIcon/>}
              onClick={() => setDialogOpen(true)}>
        AI Analysis
      </Button>

      <Dialog open={dialogOpen} onClose={() => handleDialogClose()}>
        <DialogTitle>AI Analysis
          <GeneratedOn dateToDisplay={analysisDatetimeGenerated} textVariant="caption" textPrefix="generated on"
                       regenerating={isLoadingAnalysisLatest}/>

          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              setDialogOpen(false);
              setAiAnalysis(null);
            }}
            aria-label="close"
            sx={{position: 'absolute', right: 14, top: 8}}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isLoadingAnalysisLatest && (
            <>
              <LinearProgress/>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Generating AI analysis... Hold tight!
              </Typography>
            </>
          )}
          {aiAnalysis && authenticated && (
            <Typography variant="body2">
              <ReactMarkdown>{aiAnalysis.answer}</ReactMarkdown>
            </Typography>
          )}
          {!authenticated && (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{mt: 2, width: 1}}
            >
              Please <Link href={`${paths.auth.jwt.login}?returnTo=/companies/${ticker}`}
                           onClick={handleSignUpClick} variant="body2">
              <strong><u>login</u></strong></Link> or <Link href={paths.auth.jwt.register} onClick={handleSignUpClick}
                                                            variant="body2">
              <strong><u>sign up</u></strong> </Link>
              for free to get full access to the AI Analysis feature.
            </Alert>
          )}

          {authenticated && userType !== UserTypes.PremiumWithCredits && userType !== UserTypes.FreeWithCredits && (
            <Typography variant="body2">
              You need to be a Premium user to access this feature. Start a free 10-day trial to try it out!
            </Typography>
          )
          }
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>

          <Button onClick={() => setDialogOpen(false)}> Close</Button>

          {authenticated && userType !== UserTypes.PremiumWithCredits && userType !== UserTypes.FreeWithCredits && (
            <Button variant="text" color="primary" onClick={() => navigate('/plans')}>
              Start Free Trial <Box component="span" sx={{ml: 1}}>🚀</Box>
            </Button>
          )}

        </DialogActions>
      </Dialog>
    </Box>
  );
}
