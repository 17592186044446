import React, {useContext, useState} from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

// GA
import ReactGA from "react-ga4";
import {StaticDataContext} from "../../../providers/static-data-provider";
import {StaticData} from "../../../layouts/chat/types";
import PredefinedPromptItem from "../../predefined-prompt-item/predefined-prompt-item";


interface ChatPromptHelperProps {
  sendQuestion: (question: string) => void;
  followUpQuestions?: string[];
  disabled: boolean;
}

export default function ChatPromptHelper({sendQuestion, followUpQuestions, disabled}: ChatPromptHelperProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const staticData: StaticData = useContext(StaticDataContext);

  const handleCreateChat = async () => {
    ReactGA.event({category: 'Button', action: 'Click', label: 'Create Chat'});
  };

  const handleOpen = () => {
    setDialogOpen(true);
  }

  const handleClose = () => {
    setDialogOpen(false);
  }

  const handleChoice = (prompt: string) => {
    ReactGA.event({category: 'Button', action: 'Click', label: 'Send question from prompt helper'});
    sendQuestion(prompt);
    handleClose();
  }

  const renderDialogContent = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const questions = followUpQuestions || staticData.allPredefinedPrompts;
    return <Box>
      {
        // eslint-disable-next-line react/destructuring-assignment
        questions.map((prompt: string, index: number) => (
          <Box sx={{m: 1.5}} key={`prompt-${index}`}>
            <PredefinedPromptItem
              sendQuestion={handleChoice}
              promptText={prompt}
              disabledButton={false}
              setDisabledButtons={() => {
              }}
              height="60px"
            />
          </Box>
        ))
      }
    </Box>
  };

  return (
    <>
      <Tooltip title="Get some question ideas!" placement="top">
        <Button onClick={handleOpen} sx={{p: {lg: 2.6}}} disabled={disabled}>
          <TipsAndUpdatesOutlinedIcon width={isMobile ? 15 : 24} height={isMobile ? 15 : 24}/>
        </Button>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Question ideas</DialogTitle>
        <DialogContent>
          {renderDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
