// @mui
import React from 'react';
import {Avatar, Box, Paper, Stack} from "@mui/material";
import {useTheme} from "@mui/material/styles";

import ReactMarkdown from "react-markdown";
import isEqual from "lodash/isEqual";

import SourcesPopover from "../sources-popover/sources-popover";
import {ContextDocument} from "../../custom_analysis/context_documents/types";
import TextWithActionMenu from "../../utility_components/text-with-action-menu";

interface Paragraph {
  text: string;
  relevant_documents?: any[];
}

interface Interaction {
  ai_answer: { content: string };
  ai_answer_with_sources?: { content?: { paragraphs: Paragraph[] } };
}

interface AIAnswerProps {
  interaction: Interaction;
  answerIndex: number;
  onSourceClick: (src: any) => void;
  hoveredAnswerParagraphIndices: number[];
  setHoveredAnswerParagraphIndices: (indices: number[]) => void;
}


export default function ChatAIAnswer({
                                       interaction,
                                       answerIndex,
                                       onSourceClick,
                                       hoveredAnswerParagraphIndices,
                                       setHoveredAnswerParagraphIndices
                                     }: AIAnswerProps) {
  const theme = useTheme();

  const renderAIAnswerWithoutSources = (aiAnswer: string) => (
    <Stack key={`ai-question-${answerIndex}`} id={`ai-answer-${answerIndex}`} direction="row" spacing={2}>
      <Avatar sx={{height: {xs: 25, lg: 35}, width: {xs: 25, lg: 35}}} alt="logo"
              src="/assets/images/logo/logo_without_text.png"/>
      <Paper
        sx={{
          py: {xs: 0.5, lg: 1.5},
          px: 1.5,
          flexGrow: 1,
          bgcolor: 'background.neutral',
        }}
      >
        <Stack
          sx={{mb: 0.5}}
          alignItems={{sm: 'center'}}
          justifyContent="space-between"
          direction={{xs: 'column', sm: 'row'}}
        >
          <Box sx={{typography: 'subtitle2'}}>ValueHunter AI</Box>
        </Stack>

        <Box
          sx={{
            typography: 'body2',
            color: theme.palette.grey[700],
          }}
        >
          <ReactMarkdown>{aiAnswer}</ReactMarkdown>
          {/*
          <TextWithActionMenu>
            <ReactMarkdown>{aiAnswer}</ReactMarkdown>
          </TextWithActionMenu>
          */}
        </Box>
      </Paper>
    </Stack>
  );

  const renderAIAnswerWithSources = (paragraphs: Paragraph[]) => (
    <Stack key={`ai-question-${answerIndex}`} id={`ai-answer-${answerIndex}`} direction="row" spacing={2}>
      <Avatar sx={{height: {xs: 25, lg: 35}, width: {xs: 25, lg: 35}}} alt="logo"
              src="/assets/images/logo/logo_without_text.png"/>
      <Paper
        sx={{
          py: {xs: 0.5, lg: 1.5},
          px: 1.5,
          flexGrow: 1,
          bgcolor: 'background.neutral',
        }}
      >
        <Stack
          sx={{mb: 0.5}}
          alignItems={{sm: 'center'}}
          justifyContent="space-between"
          direction={{xs: 'column', sm: 'row'}}
        >
          <Box sx={{typography: 'subtitle2'}}>ValueHunter AI</Box>
        </Stack>

        <Box
          sx={{
            typography: 'body2',
            color: theme.palette.grey[700],
          }}
        >
          {paragraphs.map((paragraph, paragraphIndex) => (
            <Box key={`content-paragraph-${paragraphIndex}`} sx={{position: 'relative'}}>
              <Box
                sx={{
                  borderRadius: 1,
                  backgroundColor: isEqual(hoveredAnswerParagraphIndices, [answerIndex, paragraphIndex]) ? theme.palette.grey[300] : "transparent",
                  transform: isEqual(hoveredAnswerParagraphIndices, [answerIndex, paragraphIndex]) ? "scale(1.02)" : "scale(1)",
                  // boxShadow: isEqual(hoveredAnswerParagraphIndices, [key, index]) ? theme.customShadows.dropdown : "none",
                  transition: "transform 0.1s ease-in-out",
                }}
              >

                <ReactMarkdown>{paragraph.text}</ReactMarkdown>
                {/*
                <TextWithActionMenu>
                  <ReactMarkdown>{paragraph.text}</ReactMarkdown>
                </TextWithActionMenu>
                */}
              </Box>
              <Stack direction="row" justifyContent="flex-end">
                <SourcesPopover
                  paragraph={paragraph}
                  onButtonHover={() => setHoveredAnswerParagraphIndices([answerIndex, paragraphIndex])}
                  onButtonHoverLeave={() => setHoveredAnswerParagraphIndices([])}
                  onSourceClick={onSourceClick}
                />
              </Stack>
            </Box>
          ))}
        </Box>
      </Paper>
    </Stack>
  );

  if (interaction.ai_answer_with_sources?.content?.paragraphs) {
    return renderAIAnswerWithSources(interaction.ai_answer_with_sources.content.paragraphs);
  }

  return renderAIAnswerWithoutSources(interaction.ai_answer.content);
};

