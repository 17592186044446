import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Tooltip} from "@mui/material";
import ScoreBreakdownProgressElement from "../company/score-breakdown-progress-element";

export default function ScoreBreakdownSmall({score, score_data}: { score: number, score_data: any }) {
  return (
    <Tooltip
      title={
        <>
          <Typography variant="body2"
                      sx={{fontSize: 12}}>Score: {score.toFixed(2)} /
            5</Typography>
          <Typography variant="body2" sx={{fontSize: 12}}>Valuation
            Score: {score_data?.price_data?.score.toFixed(2)} / 5</Typography>
          <Typography variant="body2" sx={{fontSize: 12}}>Financial Strength
            Score: {score_data?.solvency_data?.score.toFixed(2)} /
            5</Typography>
          <Typography variant="body2" sx={{fontSize: 12}}>Efficiency
            Score: {score_data?.efficiency_data?.score.toFixed(2)} /
            5</Typography>
          <Typography variant="body2" sx={{fontSize: 12}}>Growth
            Score: {score_data?.growth_data?.score.toFixed(2)} / 5</Typography>
        </>
      }
    >
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.1}>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <ScoreBreakdownProgressElement score={score_data?.price_data?.score}
                                         widthParam="8px" heightParam="32px" isVertical
                                         sx={{mx: 0.2}}/>
          <Typography sx={{fontSize: 10}}>V</Typography>
        </Stack>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <ScoreBreakdownProgressElement
            score={score_data?.solvency_data?.score}
            widthParam="8px" heightParam="32px" isVertical
            sx={{mx: 0.2}}/>
          <Typography sx={{fontSize: 10}}>F</Typography>
        </Stack>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <ScoreBreakdownProgressElement
            score={score_data?.efficiency_data?.score}
            widthParam="8px" heightParam="32px" isVertical
            sx={{mx: 0.2}}/>
          <Typography sx={{fontSize: 10}}>E</Typography>
        </Stack>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <ScoreBreakdownProgressElement score={score_data?.growth_data?.score}
                                         widthParam="8px" heightParam="32px" isVertical
                                         sx={{mx: 0.2}}/>
          <Typography sx={{fontSize: 10}}>G</Typography>
        </Stack>

      </Stack>
    </Tooltip>
  );
}
