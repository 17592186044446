import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Iconify from "../../iconify";
import {ScoredCompany2} from "../../company/types";
import {getQuickNumbersForCompany} from "../../company/utils";
import QuickRatios from "../../company/quick-ratios";

export default function RatiosPreviewDialog({
                                              company,
                                              dialogOpen,
                                              setDialogOpen,
                                            }: {
  company: ScoredCompany2;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}) {
  const quick_numbers = getQuickNumbersForCompany(company);
  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="xl">
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="material-symbols-light:call-log-outline"/>
          <Typography variant="h6">Financial Ratios</Typography>
        </Stack>

        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setDialogOpen(false)}
          aria-label="close"
          sx={{position: "absolute", right: 14, top: 8}}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{whiteSpace: "pre-line"}}>
        <QuickRatios
          valuation={quick_numbers.valuation}
          solvency={quick_numbers.solvency}
          efficiency={quick_numbers.efficiency}
          growth={quick_numbers.growth}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
