// routes
import {paths} from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="material-symbols:home-outline"/>,
    path: paths.main.home
  },
  {
    title: 'Why',
    icon: <Iconify icon="icons8:idea"/>,
    path: paths.main.why
  },
  {
    title: 'Features',
    icon: <Iconify icon="pajamas:issue-type-feature"/>,
    path: paths.main.features
  },
  // {
  //   title: 'Demo',
  //   icon: <Iconify icon="carbon:demo"/>,
  //   path: paths.main.demo
  // },
  {
    title: 'Try it',
    icon: <Iconify icon="mdi:sparkles-outline"/>,
    path: paths.main.tryIt
  },
  {
    title: 'Pricing',
    icon: <Iconify icon="material-symbols:price-check"/>,
    path: paths.main.plans
  },
  {
    title: 'Blog',
    icon: <Iconify icon="jam:message-alt-writing"/>,
    path: 'https://blog.valuehunter.net/'
  },
];

export const navConfigMobile = [
  ...[
    {
      title: 'Login',
      icon: <Iconify icon="mdi:login"/>,
      path: paths.auth.jwt.login || ''
    },
    {
      title: 'Sign Up',
      icon: <Iconify icon="mdi:account-plus"/>,
      path: paths.auth.jwt.register || ''
    }
  ],
  ...navConfig,
]
