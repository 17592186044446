import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import Iconify from "../../iconify";

export default function EdgarDocumentPreviewDialog({
  content,
  dialogOpen,
  setDialogOpen,
  url,
  date,
  documentType,
  startElementId,
  endElementId,
  searchString,
}: {
  content: string | string[];
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  url: string;
  date: string;
  documentType?: "10-K" | "10-Q" | "EARNINGS-CALL" | "PRESS-RELEASE" | "RATIOS";
  startElementId?: string;
  endElementId?: string;
  searchString?: string;
}) {
  const theme = useTheme();

  const getTitleForDocumentType = (dType?: string) => {
    switch (dType) {
      case "10-K":
        return "Annual Report";
      case "10-Q":
        return "Quarterly Report";
      case "PRESS-RELEASE":
        return "Press Release";
      default:
        return "Document";
    }
  };

  // Highlight the search string
  const highlightSearchString = (text: string) => {
    if (!searchString) return text;
    const escapedSearch = searchString.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regExp = new RegExp(`(${escapedSearch})`, "i");
    return text.replace(
      regExp,
      `<span id="highlighted-search" style="background-color: yellow; font-weight: bold;">$1</span>`
    );
  };

  useEffect(() => {
    if (dialogOpen) {
      // Logic for scrolling to start and end elements
      if (startElementId && endElementId) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            const startElement = document.getElementById(startElementId) as HTMLElement | null;
            const endElement = document.getElementById(endElementId) as HTMLElement | null;

            if (startElement && endElement) {
              const firstElement = document.getElementById(startElementId);
              if (firstElement) {
                firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
              }
              let currentElement: HTMLElement | null = startElement.nextElementSibling as HTMLElement | null;
              while (currentElement && currentElement !== endElement.nextElementSibling) {
                currentElement.style.backgroundColor = theme.palette.grey[200];
                currentElement.style.fontWeight = "bold";
                currentElement = currentElement.nextElementSibling as HTMLElement | null;
              }
            }
          });
        }, 500);
      }

      // Logic for scrolling to search string
      if (searchString) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            const highlightedSearch = document.getElementById("highlighted-search");
            if (highlightedSearch) {
              highlightedSearch.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          });
        }, 500);
      }
    }
  }, [dialogOpen, startElementId, endElementId, content, searchString, theme]);

  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="xl">
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="material-symbols-light:call-log-outline" />
          <Typography variant="h6">{getTitleForDocumentType(documentType)}</Typography>
        </Stack>

        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setDialogOpen(false)}
          aria-label="close"
          sx={{ position: "absolute", right: 14, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
          from {date} |{" "}
          <a href={url} target="_blank" rel="noopener noreferrer">
            original document <Iconify icon="eva:external-link-fill" width={10} />
          </a>
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: "pre-line" }}>
        {typeof content === "string" ? (
          <Box
            dangerouslySetInnerHTML={{ __html: highlightSearchString(content) }}
            sx={{
              m: 5,
              ".currentSection": {
                backgroundColor: theme.palette.grey[200],
                padding: 1,
                borderRadius: 0.5,
              },
              ".currentlySelected": {
                backgroundColor: theme.palette.info.light,
                padding: 1,
                borderRadius: 0.5,
                my: 1,
                transition: "transform 2s ease-in-out",
                transform: "scale(1.02)",
                fontSize: "14px !important",
              },
            }}
          />
        ) : (
          content.map((paragraph, index) => (
            <Box sx={{ mt: 1 }} key={index} id={`vh_element_${index + 1}`}>
              {paragraph.split(/:(.*)/).map((text: any, idx: any) => (
                <Typography
                  key={idx}
                  variant="body2"
                  fontWeight={idx === 0 ? 700 : 400}
                  dangerouslySetInnerHTML={{
                    __html: highlightSearchString(text.trim()),
                  }}
                />
              ))}
            </Box>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
