import {Helmet} from "react-helmet-async";
// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import {paths} from "../paths";
import TopCompanies from "../../components/collections/top_companies";
import TopInsiderBuysCompanies from "../../components/collections/insider_buys";
import MagicCompanies from "../../components/collections/magic_formula";
import PriceTargetCompanies from "../../components/collections/target_price";

export const collectionsRoutes = [
  {
    path: paths.collections.topStocks,
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Top Companies Based on Fundamental Analysis</title>
        </Helmet>
        <RouteWithHeader>
          <TopCompanies/>
        </RouteWithHeader>
      </>
    ),
  },
  {
    path: paths.collections.insiderBought,
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Top Companies Where Insiders Are Buying</title>
        </Helmet>
        <RouteWithHeader>
          <TopInsiderBuysCompanies/>
        </RouteWithHeader>
      </>
    ),
  },
  {
    path: paths.collections.magicFormula,
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Top Magic Formula Companies</title>
        </Helmet>
        <RouteWithHeader>
          <MagicCompanies/>
        </RouteWithHeader>
      </>
    ),
  },
  {
    path: paths.collections.targetPrices,
    element: (
      <>
        <Helmet>
          <title>ValueHunter: Top Positive Price Target Companies</title>
        </Helmet>
        <RouteWithHeader>
          <PriceTargetCompanies/>
        </RouteWithHeader>
      </>
    ),
  },
];
