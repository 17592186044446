// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import DiscoverLayout from "../../layouts/discover";


export const discoverRoutes = [
  {
    path: 'discover',
    element: (
      <RouteWithHeader>
        <DiscoverLayout/>
      </RouteWithHeader>
    ),
  },
];
