// @mui
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Accordion, AccordionDetails, AccordionSummary, Tab, Tabs} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import CompanyFinancialsChart from "./company-financials-chart";
import {ChartSeriesType, Financials, FinancialsRow} from './types';
import AdditionalFinancials from "./additional-financials";

interface FinancialsProps {
  ticker: string
  title: string
  financials: Financials
  additionalFinancialsAnnual: FinancialsRow[],
  additionalFinancialsQuarterly: FinancialsRow[],
  chartCategoriesAnnual: string[],
  chartCategoriesQarterly: string[],
  chartSeries: ChartSeriesType[],
  defaultExpanded?: boolean
}


export default function FinancialStatementViz(
  {
    ticker, title, financials,
    additionalFinancialsAnnual, additionalFinancialsQuarterly,
    chartCategoriesAnnual, chartCategoriesQarterly,
    chartSeries, defaultExpanded = false
  }: FinancialsProps
) {
  const [chartCategories, setChartCategories] = useState<string[]>(['2023']);
  const [additionalFinancials, setAdditionalFinancials] = useState<FinancialsRow[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState(0);

  const onPeriodChangeHook = (period: string) => {
    // @ts-ignore
    setAdditionalFinancials(
      period === 'Quarterly' ?
        additionalFinancialsQuarterly
        : additionalFinancialsAnnual
    );
    // @ts-ignore
    setChartCategories(period === 'Quarterly' ? chartCategoriesQarterly : chartCategoriesAnnual);
  }
  useEffect(() => {
    setAdditionalFinancials(additionalFinancialsAnnual);
    setChartCategories(chartCategoriesAnnual);
  }, [
    ticker, financials,
    additionalFinancialsAnnual, additionalFinancialsQuarterly,
    chartCategoriesAnnual, chartCategoriesQarterly
  ]);
  const handeCurrentPeriodChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentPeriod(newValue);
    onPeriodChangeHook(newValue === 0 ? 'Annual' : 'Quarterly');
  };
  return (
    <Box sx={{mb: 3}}>

      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            boxShadow: (theme) => `${theme.customShadows.z4}`,
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        >
          <Typography variant="subtitle1" sx={{mb: 2}}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
      <Tabs sx={{mb: 0.5}} value={currentPeriod} onChange={handeCurrentPeriodChange}
            aria-label="icon label tabs example">
        <Tab label="Annual"/>
        <Tab label="Quarterly"/>
      </Tabs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AdditionalFinancials additionalFinancials={additionalFinancials}/>
        </Grid>
        <Grid item xs={12}>
          <CompanyFinancialsChart
            onSeriesChangeHook={onPeriodChangeHook}
            chart={{
              categories: chartCategories,
              series: chartSeries,
            }}
          />
        </Grid>
      </Grid>
        </AccordionDetails>
      </Accordion>

    </Box>
  );
}
