import React, {useEffect, useState} from "react";
import {Grid, Skeleton} from "@mui/material";
import Stack from "@mui/material/Stack";

import axios, {endpoints} from "../../utils/axios";
import CompanyGrowthChart, {EstimatedGrowthData} from "./company-growth-chart";
import CompanyGrowthAiAnalysis from "./company-growth-ai-analysis";
import GeneratedOn from "../utility_components/generated-on";

interface CompanyGrowthProps {
  ticker: string | undefined;
  setAiAnalysesReady?: (key: string) => void | undefined;
}


export default function CompanyGrowth({ticker, setAiAnalysesReady}: CompanyGrowthProps) {
  const [growthData, setGrowthData] = useState<EstimatedGrowthData>({
    revenueChange: 0,
    ebitdaChange: 0,
    epsChange: 0,
    estimatedRevenueGrowth: [],
    estimatedEbitdaGrowth: [],
    estimatedEpsGrowth: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [isLoadingAnalysisLatest, setIsLoadingAnalysisLatest] = useState(false);
  const [analysisDatetimeGenerated, setAnalysisDatetimeGenerated] = useState<string>('');
  const [growthAnalysis, setGrowthAnalysis] = useState<any>({});

  useEffect(() => {
    const getCompanyGrowth = async () => {
      setIsLoading(true);
      try {
        const growthResponse = await axios.get<any>(`/api/companies/growth/${ticker}`);
        setGrowthData(growthResponse.data);
      } catch (error) {
        console.error("Error fetching company growth:", error);
      }
      setIsLoading(false);
    }

    const getCompanyGrowthAnalysis = async () => {
      const handleResponse = (response: any) => {
        setGrowthAnalysis(JSON.parse(response.data.answer));
        setAnalysisDatetimeGenerated(response.data.datetime_generated);
      }
      try {
        setIsLoadingAnalysis(true);
        const valuationAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=growth`);
        handleResponse(valuationAnalysisResponse);
        setIsLoadingAnalysis(false);

        if (!valuationAnalysisResponse.data.is_latest) {
          setIsLoadingAnalysisLatest(true);
          const latestValuationAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=growth&return_latest=true`);
          handleResponse(latestValuationAnalysisResponse);
          setIsLoadingAnalysisLatest(false);
        }
      } catch (error) {
        console.error("Error fetching company growth AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('growth');
        }
      }
    }
    getCompanyGrowth();
    getCompanyGrowthAnalysis();
  }, [ticker, setAiAnalysesReady]);

  if (isLoading) {
    return <Skeleton height={515}/>;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6} sx={{mb: 3}}>
        {isLoadingAnalysis && <Skeleton height={515}/>}
        {!isLoadingAnalysis && growthAnalysis && (
          <Stack direction="column" spacing={1}>
            <GeneratedOn dateToDisplay={analysisDatetimeGenerated} textVariant="caption" textPrefix="generated on"
                         regenerating={isLoadingAnalysisLatest}/>
            <CompanyGrowthAiAnalysis ticker={ticker} growthAnalysis={growthAnalysis}/>
          </Stack>
        )
        }
      </Grid>

      <Grid item xs={12} xl={6} sx={{mb: 3}}>
        {growthData.revenueChange && growthData.ebitdaChange && growthData.epsChange &&
          growthData.estimatedRevenueGrowth && growthData.estimatedRevenueGrowth.length > 0 &&
          growthData.estimatedEbitdaGrowth && growthData.estimatedEbitdaGrowth.length > 0 &&
          growthData.estimatedEpsGrowth && growthData.estimatedEpsGrowth.length > 0 &&
          !isLoading && (
            <CompanyGrowthChart
              revenueChange={growthData.revenueChange}
              ebitdaChange={growthData.ebitdaChange}
              epsChange={growthData.epsChange}
              estimatedRevenueGrowth={growthData.estimatedRevenueGrowth}
              estimatedEbitdaGrowth={growthData.estimatedEbitdaGrowth}
              estimatedEpsGrowth={growthData.estimatedEpsGrowth}
            />
          )}
      </Grid>
    </Grid>
  );
}
