// @mui
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Skeleton} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios, {endpoints} from "../../utils/axios";
import {AIAnalysisTypesEnum, Financials} from "./types";
import {
  buildBalanceSheetPeriodsData,
  buildCashFlowPeriodsData,
  buildChartCategoriesBalanceSheet,
  buildChartCategoriesCashFlow,
  buildChartCategoriesIncomeStatement,
  buildChartSeriesBalanceSheet,
  buildChartSeriesCashFlow,
  buildChartSeriesIncomeStatement,
  buildIncomeStatementPeriodsData,
} from "./financials-utils";

import FinancialStatementViz from "./financial-statement-viz";
import AIAnalysis from "./a-i-analysis";


interface FinancialsProps {
  ticker: string;
  setAiAnalysesReady?: (key: string) => void | undefined;
}


export default function CompanyFinancials({ticker, setAiAnalysesReady}: FinancialsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [financials, setFinancials] = useState<Financials>();
  const [chartCategories, setChartCategories] = useState<string[]>(['2023']);
  const seriesTypeToCategories = {
    'Quarterly': financials?.quarterly.income_statement.map((item: any) => `${item.period} ${item.calendarYear}`),
    'Annual': financials?.annual.income_statement.map((item: any) => item.calendarYear),
  }

  const onSeriesChangeHook = (series: string) => {
    // @ts-ignore
    setChartCategories(seriesTypeToCategories[series]);
  }
  useEffect(() => {
    const reverseFinancials = (fins: Financials) => ({
      annual: {
        balance_sheet: fins.annual.balance_sheet.reverse(),
        income_statement: fins.annual.income_statement.reverse(),
        cash_flow_statement: fins.annual.cash_flow_statement.reverse(),
      },
      quarterly: {
        balance_sheet: fins.quarterly.balance_sheet.reverse(),
        income_statement: fins.quarterly.income_statement.reverse(),
        cash_flow_statement: fins.quarterly.cash_flow_statement.reverse(),
      }
    })

    const getFinancialsData = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.companies.financials}/${ticker}`);
        const reversedFinancials: Financials = reverseFinancials(result.data);
        setFinancials(reversedFinancials);
        setChartCategories(reversedFinancials.annual.income_statement.map((item: any) => item.calendarYear));
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };


    getFinancialsData();
  }, [ticker]);

  return (
    <Box className="financials" sx={{mb: 3}}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Stack id="financials" direction="row" spacing={1}>
            <Typography variant="h4" sx={{color: 'text.primary', pt: "1px", mb: 2}}>
              Financials
            </Typography>
            <AIAnalysis ticker={ticker} analysisType={AIAnalysisTypesEnum.AnnualFinancials}
                        setAiAnalysesReady={setAiAnalysesReady}/>
          </Stack>

          {!financials && isMobile && (
            <>
              <Skeleton variant="text" height={50}/>
              <Skeleton height={815}/>
              <Skeleton variant="text" height={50}/>
              <Skeleton variant="text" height={50}/>
            </>
          )}

          {!financials && !isMobile && (
            <>
              <Skeleton variant="text" height={50}/>
              <Skeleton height={715}/>
              <Skeleton variant="text" height={50}/>
              <Skeleton variant="text" height={50}/>
            </>
          )}

          {financials && (
            <>
              <Box sx={{mb: 3}} id="income-statement">
                <FinancialStatementViz
                  ticker={ticker}
                  title="Income Statement"
                  financials={financials}
                  additionalFinancialsAnnual={buildIncomeStatementPeriodsData(financials, 'Annual')}
                  additionalFinancialsQuarterly={buildIncomeStatementPeriodsData(financials, 'Quarterly')}
                  chartCategoriesAnnual={buildChartCategoriesIncomeStatement(financials, 'Annual')}
                  chartCategoriesQarterly={buildChartCategoriesIncomeStatement(financials, 'Quarterly')}
                  chartSeries={buildChartSeriesIncomeStatement(financials)}
                  defaultExpanded
                />
              </Box>
              <Box sx={{mb: 3}} id="balance-sheet">
                <FinancialStatementViz
                  ticker={ticker}
                  title="Balance Sheet"
                  financials={financials}
                  additionalFinancialsAnnual={buildBalanceSheetPeriodsData(financials, 'Annual')}
                  additionalFinancialsQuarterly={buildBalanceSheetPeriodsData(financials, 'Quarterly')}
                  chartCategoriesAnnual={buildChartCategoriesBalanceSheet(financials, 'Annual')}
                  chartCategoriesQarterly={buildChartCategoriesBalanceSheet(financials, 'Quarterly')}
                  chartSeries={buildChartSeriesBalanceSheet(financials)}
                />
              </Box>
              <Box id="cash-flow">
                <FinancialStatementViz
                  ticker={ticker}
                  title="Cash Flow"
                  financials={financials}
                  additionalFinancialsAnnual={buildCashFlowPeriodsData(financials, 'Annual')}
                  additionalFinancialsQuarterly={buildCashFlowPeriodsData(financials, 'Quarterly')}
                  chartCategoriesAnnual={buildChartCategoriesCashFlow(financials, 'Annual')}
                  chartCategoriesQarterly={buildChartCategoriesCashFlow(financials, 'Quarterly')}
                  chartSeries={buildChartSeriesCashFlow(financials)}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
