import React, {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress"; // Import LinearProgress

// GA
import ReactGA from "react-ga4";

import ChatCompanyAutocomplete from "../chat-company-autocomplete/chat-company-autocomplete";
import axios, {endpoints} from "../../../utils/axios";
import {Company} from "../../../layouts/company/types";

interface CompanyDialogProps {
  companies: Map<number, Company>;
  dialogOpen: boolean;
  handleSuccess: () => void;
  handleError: () => void;
  handleClose: () => void;
}

export interface Chat {
  id: string;
  company_id: number;
  user_id: number;
}

export default function ChatCreateDialog({
                                              companies,
                                              dialogOpen,
                                              handleSuccess,
                                              handleError,
                                              handleClose,
                                            }: CompanyDialogProps) {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [loading, setLoading] = useState(false); // Add a loading state

  const handleCompanySelection = (companyId: number | null) => {
    setSelectedCompanyId(companyId);
  };

  const handleCreateChat = async () => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Create Chat' });
    if (selectedCompanyId) {
      setLoading(true); // Set loading to true before sending the request
      await createChat(selectedCompanyId);
      setLoading(false); // Set loading back to false after the request
    } else {
      console.warn("No company selected");
    }
  };

  const createChat = async (company_id: number) => {
    try {
      const result = await axios.post<Chat>(
        endpoints.chat,
        {
          company_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      handleSuccess();
      handleClose();
      console.log("Chat created successfully:", result.data);
    } catch (error) {
      // Handle error if the request fails
      handleError();
      handleClose();
      console.error("An error occurred while creating the chat:", error);
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogTitle>Create a new chat</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose the company you want to dive into.
        </DialogContentText>
        <ChatCompanyAutocomplete
          companies={companies}
          onSelectedCompany={handleCompanySelection}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleCreateChat} disabled={selectedCompanyId == null}>
          Create
        </Button>
      </DialogActions>
      {loading && <LinearProgress/>}
    </Dialog>
  );
}
