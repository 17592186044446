// React
import React, {useEffect, useState} from "react";
// @mui
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Box, IconButton, Snackbar, Stack, useMediaQuery} from "@mui/material";
import Alert from "@mui/material/Alert";
import {useLocation, useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

// components
import {useAuthContext} from "../../auth/hooks";
import TopCompanies from "../../components/collections/top_companies";
import Watchlist from "../../components/watchlist/watchlist";
import Iconify from "../../components/iconify";


export default function DashboardLayout() {
  const {user} = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation()

  const [afterRegister, setAfterRegister] = useState(user?.is_new && !localStorage.getItem('afterRegisterMessageShown'));
  const [currentLocationHash, setCurrentLocationHash] = useState(location?.hash !== "" ? location.hash : '#watchlist');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (location.hash !== "") {
      setCurrentLocationHash(location.hash);
    } else {
      setCurrentLocationHash('#watchlist');
    }
  }, [location]);

  const handleAfterRegisterClose = () => {
    setAfterRegister(false);
    localStorage.setItem('afterRegisterMessageShown', 'true');
  }

  const renderSnackbar = (
    <Snackbar open={afterRegister} autoHideDuration={10000} onClose={handleAfterRegisterClose}>
      <Alert onClose={handleAfterRegisterClose} severity="success" sx={{width: '100%'}}>
        You have registered successfully! Welcome to ValueHunter!
      </Alert>
    </Snackbar>
  )

  const renderDashboardNavigationDesktop = (
    <Box sx={{
      my: 2,
    }}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <Button
          size="large"
          startIcon={<Iconify icon="bx:bx-bookmark"/>}
          onClick={() => navigate('/dashboard#watchlist')}
          sx={{
            borderBottom: currentLocationHash === '#watchlist' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          Watchlist
        </Button>
        <Button
          size="large"
          startIcon={<Iconify icon="hugeicons:medal-first-place"/>}
          onClick={() => navigate('/dashboard#top-companies')}
          sx={{
            borderBottom: currentLocationHash === '#top-companies' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          Top Companies Today
        </Button>

      </Stack>
    </Box>
  );

  const renderDashboardNavigationMobile = (
    <Box sx={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      bgcolor: 'background.paper',
      boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
      py: 0.5,
    }}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <IconButton
          onClick={() => navigate('/dashboard#watchlist')}
          sx={{
            borderBottom: currentLocationHash === '#watchlist' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          <Stack direction="column" alignItems="center">
            <Iconify icon="bx:bx-bookmark"/>
            <Typography variant="body2" sx={{fontSize: 10}}>Watchlist</Typography>
          </Stack>
        </IconButton>
        <IconButton
          onClick={() => navigate('/dashboard#top-companies')}
          sx={{
            borderBottom: currentLocationHash === '#top-companies' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          <Stack direction="column" alignItems="center">
            <Iconify icon="hugeicons:medal-first-place"/>
            <Typography variant="body2" sx={{fontSize: 10}}>Top Companies</Typography>
          </Stack>
        </IconButton>
      </Stack>
    </Box>
  );

  return (
    <Box sx={{
      mx: {
        xs: 2,
        md: 10,
        lg: 10,
        xl: 30
      },
      pb: isMobile ? 8 : 0, // Add padding to the bottom to avoid content being hidden behind the navigation
    }}>
      {!isMobile && renderDashboardNavigationDesktop}
      {currentLocationHash === '#watchlist' && <Watchlist/>}
      {currentLocationHash === '#top-companies' && <TopCompanies/>}
      {isMobile && renderDashboardNavigationMobile}
      {renderSnackbar}
    </Box>
  );
}
