import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";

import PredefinedPromptItem from "../predefined-prompt-item/predefined-prompt-item";
import {StaticData} from "../../layouts/chat/types";
import {StaticDataContext} from "../../providers/static-data-provider";

interface PredefinedPromptItemProps {
  sendQuestion: (question: string) => void;
}

export default function PredefinedPrompt({
                                           sendQuestion,
                                         }: PredefinedPromptItemProps) {
  const [prompts, setPrompts] = useState<string[]>([]);
  const [disableButtons, setDisableButtons] = useState(false);
  const {highPriorityPredefinedPrompts}: StaticData = useContext(StaticDataContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const renderDesktop = () => (
    <Box>
      <Grid container spacing={3} justifyContent="center">
        {highPriorityPredefinedPrompts.map((promptText, idx) => (
          <Grid key={`predefined-prompt-grid-${idx}`} item xs={isMobile ? 12 : 5} style={isMobile ? {} : {flexBasis: 'calc(50% - 16px)'}}>
            {/* 16px is spacing (8px on each side for default spacing=1) */}
            <Box style={{height: '70px'}}> {/* Set any specific height you want */}
              <PredefinedPromptItem
                sendQuestion={sendQuestion}
                promptText={promptText}
                disabledButton={disableButtons}
                setDisabledButtons={setDisableButtons}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <>
      {renderDesktop()}
    </>
  );
}
