import React, {useCallback, useState} from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Typography from "@mui/material/Typography";
import Chart, {useChart} from 'src/components/chart';
import ButtonBase from "@mui/material/ButtonBase";
import MenuItem from "@mui/material/MenuItem";
import {Box} from "@mui/material";


import Iconify from "../iconify";
import CustomPopover, {usePopover} from "../custom-popover";


interface Historical {
  date: string;
  calendarYear: string;
  period: string;
  value: number;
}

export interface HistoricalData {
  priceToEarnings: {
    mean: number;
    historical: Historical[];
  },
  priceToBook: {
    mean: number;
    historical: Historical[];
  },
  priceToSales: {
    mean: number;
    historical: Historical[];
  },
  priceToFreeCashFlows: {
    mean: number;
    historical: Historical[];
  },
  priceEarningsToGrowth: {
    mean: number;
    historical: Historical[];
  }
}

export default function CompanyValuationHistoricalChart({
                                                          priceToEarnings,
                                                          priceToBook,
                                                          priceToSales,
                                                          priceToFreeCashFlows,
                                                          priceEarningsToGrowth
                                                        }: HistoricalData) {
  const theme = useTheme();
  const successLight = theme.palette.primary.light;
  const grey400 = theme.palette.grey[400];

  const popover = usePopover();
  const [currentSeriesKey, setCurrentSeriesKey] = useState('P/E Ratio');

  const peSeriesValues: number[] = priceToEarnings.historical.map(data => data.value);
  const pbSeriesValues: number[] = priceToBook.historical.map(data => data.value);
  const psSeriesValues: number[] = priceToSales.historical.map(data => data.value);
  const pfcfSeriesValues: number[] = priceToFreeCashFlows.historical.map(data => data.value);
  const pegSeriesValues: number[] = priceEarningsToGrowth.historical.map(data => data.value);


  const seriesKeyToMean: { [key: string]: number } = {
    "P/E Ratio": priceToEarnings.mean,
    "P/B Ratio": priceToBook.mean,
    "P/Sales Ratio": priceToSales.mean,
    "P/FCF Ratio": priceToSales.mean,
    "PEG Ratio": priceEarningsToGrowth.mean,
  }

  const seriesKeyToData: { [key: string]: number[] } = {
    "P/E Ratio": peSeriesValues,
    "P/B Ratio": pbSeriesValues,
    "P/Sales Ratio": psSeriesValues,
    "P/FCF Ratio": pfcfSeriesValues,
    "PEG Ratio": pegSeriesValues,
  }
  const categoriesValues = priceToEarnings.historical.map(data => data.date);
  const latestDate: string = categoriesValues[0];
  const oneYearAgo = new Date(latestDate).setFullYear(new Date(latestDate).getFullYear() - 1);
  const handleChangeSeries = useCallback((newValue: string) => {
    popover.onClose();
    setCurrentSeriesKey(newValue);
  }, [popover, setCurrentSeriesKey]);

  const optionsLine = useChart({
    series: [
      {
        name: currentSeriesKey,
        data: seriesKeyToData[currentSeriesKey],
      },
    ],
    chart: {
      id: 'chart2',
      type: 'line',
      height: 230,
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: [successLight],
    stroke: {
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      categories: categoriesValues,
    }
  });

  // @ts-ignore
  const optionsArea = useChart({
    series: [
      {
        name: currentSeriesKey,
        data: seriesKeyToData[currentSeriesKey],
      },
    ],
    annotations: {
      yaxis: [
        {
          y: seriesKeyToData[currentSeriesKey].reduce((a, b) => a + b, 0) / seriesKeyToData[currentSeriesKey].length,
          fillColor: '#B3F7CA',
          // borderColor: grey400,
          borderWidth: 2,
          opacity: 0.2,
          label: {
            position: 'center',
            style: {
              fontSize: '14px',
              // color: '#fff',
              // background: grey400,
              padding: {
                left: 3,
                right: 3,
                top: 3,
                bottom: 3
              }
            },
            // @ts-ignore
            text: `Avg: ${seriesKeyToMean[currentSeriesKey]}`
          }
        }
      ]
    },
    chart: {
      id: 'chart1',
      type: 'area',
      brush: {
        target: 'chart2',
        enabled: true
      },
      selection: {
        enabled: true,
        xaxis: {
          min: new Date(oneYearAgo).getTime(),
          max: new Date(latestDate).getTime()
        }
      },
    },
    colors: [successLight],
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      }
    },
    xaxis: {
      type: 'datetime',
      categories: categoriesValues,
      tooltip: {
        enabled: false
      }
    },
  });
  if (!peSeriesValues.length && !pbSeriesValues.length && !psSeriesValues.length && !pfcfSeriesValues.length && !pegSeriesValues.length) {
    return (
      <Box sx={{mb: 3}}>
        <Typography variant="h6" sx={{color: 'text.primary'}}>
          Historical Value Analysis
        </Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Intrinsic value data not available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Card>
        <CardHeader
          title="vs History"
          subheader={
            <Typography
              variant="body2"
              component="span"
              color="text.secondary"
            >
              {currentSeriesKey}: <b>{seriesKeyToData[currentSeriesKey][0]}</b>
            </Typography>
          }
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {currentSeriesKey}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ml: 0.5}}
              />
            </ButtonBase>
          }
        />
        <Chart type="line"
               dir="ltr"
               series={optionsLine.series}
               options={optionsLine}
               height={230}
        />
        <Chart type="area"
               dir="ltr"
               series={optionsArea.series}
               options={optionsArea}
               height={150}
        />
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 140}}>
        {Object.keys(seriesKeyToData).map((key) => (
          <MenuItem
            key={key}
            selected={key === currentSeriesKey}
            onClick={() => handleChangeSeries(key)}
          >
            {key}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
