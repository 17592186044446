import React, {useEffect, useState} from "react";
import {Grid, Skeleton} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios, {endpoints} from "../../utils/axios";
import CompanyBusinessRevenueBreakdown, {RevenueBreakdownType} from "./company-business-revenue-breakdown";
import {AIAnalysisTypesEnum} from "./types";
import CompanyBusinessAiAnalysis from "./company-business-ai-analysis";
import Iconify from "../iconify";
import GeneratedOn from "../utility_components/generated-on";


interface CompanyDocumentsProps {
  ticker: string | undefined;
  companyContext: any | undefined;
  setAiAnalysesReady?: (key: string) => void | undefined;
}

const defaultRevenueBreakdown: RevenueBreakdownType = {
  product_breakdown: {
    date: '',
    breakdown: {},
  },
  geo_breakdown: {
    date: '',
    breakdown: {},
  }
}

export default function CompanyBusiness({ticker, companyContext, setAiAnalysesReady}: CompanyDocumentsProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(true);
  const [isLoadingAnalysisLatest, setIsLoadingAnalysisLatest] = useState(false);
  const [analysisDatetimeGenerated, setAnalysisDatetimeGenerated] = useState<string>('');


  const [revenueBreakdown, setRevenueBreakdown] = useState<RevenueBreakdownType>(defaultRevenueBreakdown);
  const [businessAnalysis, setBusinessAnalysis] = useState<any>({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));


  useEffect(() => {
    const getRevenueBreakdown = async () => {
      setIsLoading(true);
      try {
        const [revenueBreakdownResponse] = await Promise.all([
          axios.get<any>(`${endpoints.companies.revenueBreakdown}/${ticker}`)
        ]);
        setRevenueBreakdown(revenueBreakdownResponse.data);
      } catch (error) {
        console.error("Error fetching AI analysis:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const getBusinessAiAnalysis = async () => {
      if (companyContext === undefined) {
        return;
      }
      const handleResponse = (response: any) => {
        setBusinessAnalysis(response.data.answer);
        setAnalysisDatetimeGenerated(response.data.datetime_generated);
      }

      try {
        setIsLoadingAnalysis(true);
        const analysisType = AIAnalysisTypesEnum.BusinessModel;
        const businessAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=${analysisType}`);
        handleResponse(businessAnalysisResponse);
        setIsLoadingAnalysis(false);

        if (!businessAnalysisResponse.data.is_latest) {
          setIsLoadingAnalysisLatest(true);
          const latestBusinessAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=${analysisType}&return_latest=true`);
          setIsLoadingAnalysisLatest(false);
          handleResponse(latestBusinessAnalysisResponse);
        }

      } catch (error) {
        console.error("Error fetching company valuation AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('business');
        }
      }

    }


    getRevenueBreakdown();
    getBusinessAiAnalysis()
  }, [ticker, setAiAnalysesReady, companyContext]);


  if (!isLoadingAnalysis && Object.keys(businessAnalysis).length === 0) {
    return (
      <Stack sx={{mb: 3, height: '100%'}} spacing={1} direction="column" alignItems="center" justifyContent="center">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Iconify width={28} icon='icon-park-twotone:attention' sx={{color: 'error.main'}}/>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Business Analysis
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          No business analysis available.
        </Typography>
      </Stack>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{mb: 1}}>
        {isLoadingAnalysis && !isMobile &&
          <>
            <Skeleton variant="text" height={50}/>
            <Skeleton height={200}/>
            <Skeleton variant="text" height={50}/>
            <Skeleton height={300}/>
            <Skeleton variant="text" height={50}/>
            <Skeleton height={300}/>
            <Skeleton variant="text" height={50}/>
            <Skeleton height={400}/>
          </>}
        {isLoadingAnalysis && isMobile && <Skeleton height={600}/>}
        {!isLoadingAnalysis && (
          <Stack direction="column" spacing={1}>
            <GeneratedOn dateToDisplay={analysisDatetimeGenerated} textVariant="caption" textPrefix="generated on"
                         regenerating={isLoadingAnalysisLatest}/>
            <CompanyBusinessAiAnalysis aiAnalysis={businessAnalysis}/>
          </Stack>
        )
        }
      </Grid>

      <Grid item xs={12} sx={{mb: 1}}>
        {isLoading && <Skeleton height={isMobile ? 600 : 300}/>}
        {!isLoading &&
          <CompanyBusinessRevenueBreakdown product_breakdown={revenueBreakdown.product_breakdown}
                                           geo_breakdown={revenueBreakdown.geo_breakdown}/>
        }
      </Grid>
    </Grid>
  );
}
