import {Box, Button, Typography, Stack} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import ContextDocumentLabel from "../../custom_analysis/context_documents/context_document_label";
import CustomPopover, {usePopover} from "../../custom-popover";
import {customScrollbarStyles} from "../../../theme/css";

interface SourcesPopoverProps {
  paragraph: any;
  onButtonHover: () => void;
  onButtonHoverLeave: () => void;
  onSourceClick: (src: any) => void;
}

export default function SourcesPopover({paragraph, onButtonHover, onButtonHoverLeave, onSourceClick}: SourcesPopoverProps) {
  const theme = useTheme();
  const popover = usePopover();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const onSourceClickHandler = (src: any) => {
    popover.onClose();
    onSourceClick(src);
  }

  const renderPopoverContent = () => (
    <Box
      sx={{
        p: 2
      }}
    >
      <Stack direction="column" spacing={1}
             sx={{
               maxHeight: 200,
               overflowY: "auto",
               ...customScrollbarStyles(theme),
             }}
      >
        {paragraph.relevant_documents &&
          paragraph.relevant_documents.length > 0 &&
          paragraph.relevant_documents.map((src: any, sIndex: number) => (
            <Stack
              key={`source-${sIndex}`}
              direction="row"
              spacing={1}
              onClick={() => onSourceClickHandler(src)}
              sx={{cursor: "pointer", "&:hover": {color: "primary.main"}}}
            >
              <Box sx={{minWidth: "95px"}}>
                <ContextDocumentLabel type={src.metadata.document_type}/>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.grey[600],
                  borderLeft: "2px solid lightgrey",
                  paddingLeft: 1,
                }}
              >
                &quot;{src?.page_content?.slice(0, 100)}...&quot;
              </Typography>
            </Stack>
          ))}
      </Stack>
    </Box>
  );

  if (!paragraph.relevant_documents || paragraph.relevant_documents.length === 0) {
    return null;
  }

  return (
    <>
      <Button ref={buttonRef} onClick={popover.onOpen}
              onMouseEnter={() => onButtonHover()}
              onMouseLeave={() => onButtonHoverLeave()}
      >
        <Typography variant="caption" sx={{color: theme.palette.grey[600]}}>
          Sources
        </Typography>
      </Button>
      <CustomPopover
        arrow="bottom-center"
        open={popover.open}
        onClose={popover.onClose}
        anchorEl={buttonRef.current}
        sx={{width: 430, p: 0}} // Set the maximum height for the popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {renderPopoverContent()}
      </CustomPopover>
    </>
  );
}
