import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Link from "@mui/material/Link";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

import {AuthContext} from "../../auth/context/jwt";
import LoginRegisterDialog from "./login-register-dialog";


export default function NoCreditsDialog({open, onClose}: { open: boolean, onClose: () => void }) {
  const {user, authenticated} = useContext(AuthContext);
  const navigate = useNavigate();


  const handleMailto = () => {
    window.location.href = 'mailto:contact@valuehunter.net?subject=I have run out of credits.';
  };

  const renderTitle = () => "You have run out of credits"

  const renderContent = (
    user?.subscription?.is_active ?
      <Typography variant="body2" sx={{color: 'text.secondary'}}>
        Contact us at <Link onClick={handleMailto}
                            sx={{cursor: "pointer"}}>contact@valuehunter.net</Link> to get more
        credits.
      </Typography> :
      <Typography variant="body2" sx={{color: 'text.secondary'}}>
        Start our risk-free free trial to get more Copilot prompts.
      </Typography>
  )

  const renderAction = (
    user?.subscription?.is_active ?
      <Button variant="text" color="primary" onClick={handleMailto}>
       Contact us <Box component="span" sx={{ml: 1}}>✉️</Box>
      </Button> :
      <Button variant="text" color="primary" onClick={() => navigate('/plans')}>
       Start Free Trial <Box component="span" sx={{ml: 1}}>🚀</Box>
      </Button>
  )
  if (!authenticated) {
    return <LoginRegisterDialog dialogOpen={open} onClose={onClose}/>
  }

  return <Dialog open={open} onClose={onClose}>
    <DialogTitle>{renderTitle()}
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{position: 'absolute', right: 14, top: 8}}
      >
        <CloseIcon/>
      </IconButton>


    </DialogTitle>
    <DialogContent>
      {renderContent}
    </DialogContent>
    <DialogActions sx={{justifyContent: 'space-between'}}>
      <Button onClick={onClose}>Cancel</Button>
      {renderAction}
    </DialogActions>
  </Dialog>;
}

