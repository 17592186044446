// @mui
import React, {useContext, useState} from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card, {CardProps} from '@mui/material/Card';
import {Rating} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {ScoreData} from './types';
import ScoreBreakdownProgressElement from './score-breakdown-progress-element';
import {fShortenNumber} from "../../utils/format-number";
import {defaultGrowthScore} from "./constants";
import {getProgressBarColor} from "./utils";
import {paths} from "../../routes/paths";
import {AuthContext} from "../../auth/context/jwt";
import {BecomePremiumToAccessDialog} from "../utility_components/become-premium-to-access-dialog";
// utils

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  scoreData: ScoreData;
  overallScore: number;
}

type ScoreDataKey = keyof ScoreData;

interface ScoreBreakdownProgressParams {
  onMouseEnter: (e: any) => void;
  onMouseLeave: () => void;
  badLabel: string;
  value: any;
  showHelpCursor?: boolean;
}


function ScoreBreakdownProgress({
                                  badLabel,
                                  onMouseEnter,
                                  onMouseLeave,
                                  value,
                                  showHelpCursor = true
                                }: ScoreBreakdownProgressParams) {

  const theme = useTheme();

const hoverStyles = {
  transition: 'transform 0.2s ease-in-out',
  "&:hover": {
    cursor: showHelpCursor ? "help" : "pointer",
    transform: 'scale(1.005)' // Slightly enlarge on hover
  },
}
  const primaryLight = theme.palette.primary.main;
  const errorLight = theme.palette.error.dark;
  return <Stack
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    sx={hoverStyles}
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{mb: 1}}
    >
      <Box sx={{typography: "overline", color: errorLight, fontSize: 10, width: "120px"}}>{badLabel}</Box>
      <Box sx={{typography: "subtitle1", fontSize: 12}}>{value ? fShortenNumber(value.score) : "N/A"}</Box>
    </Stack>

    <ScoreBreakdownProgressElement score={value ? value.score : defaultGrowthScore}/>
  </Stack>;
}


export default function ScoreBreakdown({title, subheader, overallScore, scoreData, ...other}: Props) {
  // State for managing Popover and its content
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState<any>(null);
  const navigate = useNavigate();
  const {authenticated} = useContext(AuthContext);
  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);


  const popoverContentMapping = {
    price_data: {
      "High score = Undervalued": "",
      "Price To Earnings": scoreData.price_data.price_to_earnings.toFixed(2),
      "Price To Book": scoreData.price_data.price_to_book.toFixed(2),
      "Price To Free Cash Flow": scoreData.price_data.price_to_free_cashflow.toFixed(2),
      "Enterprise Value To EBITDA": scoreData.price_data.ev_to_ebitda.toFixed(2),
      "Price To Earnings Growth": scoreData.price_data.peg.toFixed(2),
    },
    solvency_data: {
      "High score = Financially stable": "",
      "Current Ratio": scoreData.solvency_data.current_ratio.toFixed(2),
      "Quick Ratio": scoreData.solvency_data.quick_ratio.toFixed(2),
      "Debt To Equity": scoreData.solvency_data.debt_to_equity.toFixed(2),
      "Debt To Assets": scoreData.solvency_data.debt_to_assets.toFixed(2),
      "Interest Coverage Ratio": scoreData.solvency_data.interest_coverage_ratio.toFixed(2),
      "Piotroski F-Score": scoreData.solvency_data.piotroski_score?.toFixed(2),
    },
    growth_data: {
      "High score = Good growth potential": "",
      "Expected Revenue Growth (1 year)": scoreData.growth_data ? `${scoreData.growth_data.revenue_1y_expected_change.toFixed(2)} %` : "N/A",
      "Expected EBITDA Growth (1 year)": scoreData.growth_data ? `${scoreData.growth_data.ebitda_1y_expected_change.toFixed(2)} %` : "N/A",
      "Expected EPS Growth (1 year)": scoreData.growth_data ? `${scoreData.growth_data.eps_1y_expected_change.toFixed(2)} %` : "N/A",
      "Past Revenue Growth (5 years)": scoreData.growth_data ? `${scoreData.growth_data.revenue_5y_actual_change} %` : "N/A",
      "Past EBITDA Growth (5 years)": scoreData.growth_data ? `${scoreData.growth_data.ebitda_5y_actual_change} %` : "N/A",
      "Past EPS Growth (5 years)": scoreData.growth_data ? `${scoreData.growth_data.eps_5y_actual_change} %` : "N/A",
      "Revenue Growth Consistency (5 years)": scoreData.growth_data ? `${scoreData.growth_data.revenue_growth_consistency} %` : "N/A",
      "EBITDA Growth Consistency (5 years)": scoreData.growth_data ? `${scoreData.growth_data.ebitda_growth_consistency} %` : "N/A",
      "EPS Growth Consistency (5 years)": scoreData.growth_data ? `${scoreData.growth_data.eps_growth_consistency} %` : "N/A",
    },
    efficiency_data: {
      "High score = Efficient operations": "",
      "Return On Capital Employed": `${(scoreData.efficiency_data.return_on_capital_employed * 100).toFixed(2)} %`,
      "Return On Equity": `${(scoreData.efficiency_data.return_on_equity * 100).toFixed(2)} %`,
      "Return On Assets": `${(scoreData.efficiency_data.return_on_assets * 100).toFixed(2)} %`,
      "Operating Profit Margin": `${(scoreData.efficiency_data.operating_profit_margin * 100).toFixed(2)} %`,
      "Net Profit Margin": `${(scoreData.efficiency_data.net_profit_margin * 100).toFixed(2)} %`,
      "Asset Turnover": `${(scoreData.efficiency_data.asset_turnover * 100).toFixed(2)} %`,
    }
  }

  const handlePopoverOpen = (event: any, dataKey: ScoreDataKey) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popoverContentMapping[dataKey]);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null); // Clear the content when popover is closed
  };

  const renderRating = (overallSc: number) => {
    let progressColor = getProgressBarColor(overallSc * 20);
    if (!authenticated) {
      // return blurred rating
      overallSc = 3.4;
      progressColor = getProgressBarColor(overallSc * 20);
      return (
        <Stack direction="row" sx={{filter: "blur(5px)", cursor: "pointer"}} onClick={() => setPremiumDialogOpen(true)}>
          <Rating name="overall-score" precision={0.01} value={overallSc}
                  readOnly
                  size="large"
                  sx={{
                    '& .MuiRating-iconFilled': {
                      color: progressColor,
                    },
                  }}
          />
          <Typography variant="overline" sx={{ml: 1, fontSize: 20}}>
            {overallSc.toFixed(1)}
          </Typography>
        </Stack>
      );

    }
    return (<Stack direction="row">
        <Rating name="overall-score" precision={0.1} value={overallScore}
                readOnly
                size="large"
                sx={{
                  '& .MuiRating-iconFilled': {
                    color: progressColor,
                  },
                }}
        />
        <Typography variant="overline" sx={{ml: 1, fontSize: 20}}>
          {overallScore.toFixed(1)}
        </Typography>
      </Stack>
    );
  }

  const renderBreakdown = (data: ScoreData) => {
    if (!authenticated)
      return (
        <Box sx={{filter: "blur(5px)"}} onClick={() => setPremiumDialogOpen(true)}>
          <ScoreBreakdownProgress badLabel="Valuation" onMouseEnter={() => {
          }} onMouseLeave={() => {
          }} value={{score: 2}} showHelpCursor={false}/>
          <ScoreBreakdownProgress badLabel="Financial Strength" onMouseEnter={() => {
          }} onMouseLeave={() => {
          }} value={{score: 4}} showHelpCursor={false}/>
          <ScoreBreakdownProgress badLabel="Efficiency" onMouseEnter={() => {
          }} onMouseLeave={() => {
          }} value={{score: 3}} showHelpCursor={false}/>
          <ScoreBreakdownProgress badLabel="Future Growth" onMouseEnter={() => {
          }} onMouseLeave={() => {
          }} value={{score: 1}} showHelpCursor={false}/>
        </Box>
      )

    return (
      <>
        <ScoreBreakdownProgress badLabel="Valuation"
                                onMouseEnter={(e) => handlePopoverOpen(e, "price_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.price_data}/>
        <ScoreBreakdownProgress badLabel="Financial Strength"
                                onMouseEnter={(e) => handlePopoverOpen(e, "solvency_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.solvency_data}/>
        <ScoreBreakdownProgress badLabel="Efficiency"
                                onMouseEnter={(e) => handlePopoverOpen(e, "efficiency_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.efficiency_data}/>
        <ScoreBreakdownProgress badLabel="Future Growth"
                                onMouseEnter={(e) => handlePopoverOpen(e, "growth_data")}
                                onMouseLeave={handlePopoverClose} value={scoreData.growth_data}/>
      </>
    )

  }

  const open = Boolean(anchorEl);
  return (
    <Card {...other} sx={{height: '100%'}}>

      <Stack spacing={1} sx={{p: 3}}>
        <Stack direction="row" spacing={1} sx={{mb: 2}}>
          <Typography variant="h6">Score</Typography>
          <Box sx={{position: "relative", width: "100%"}}>
            <Button
              size="small"
              color="primary"
              sx={{fontSize: "12px", position: "absolute", bottom: 12}}
              onClick={() => navigate(paths.informational.scoring_algorithm)}
            >
              How this works?
            </Button>
          </Box>
        </Stack>


        <Stack sx={{mb: 2}}>
          <Box sx={{typography: "overline", fontSize: 12}}>Overall</Box>
          {renderRating(overallScore)}
        </Stack>

        {renderBreakdown(scoreData)}

      </Stack>

      <Popover
        id="mouse-over-popover"
        sx={{pointerEvents: 'none'}}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        {popoverContent && (

          <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" sx={{m: 1.5}}>
            {
              Object.entries(popoverContent).map(([k, v]) => (
                <Typography key={k} variant="caption">
                  {v ? (
                    <>
                      <strong>{k}:</strong> {v}
                    </>
                  ) : (
                    <strong>{k}</strong>
                  )}
                </Typography>
              ))
            }
          </Stack>
        )

        }
      </Popover>
      <BecomePremiumToAccessDialog open={premiumDialogOpen} onClose={() => setPremiumDialogOpen(false)} ticker={undefined}/>
    </Card>
  );
}
