import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import ReactMarkdown from "react-markdown";


import {useChart} from 'src/components/chart';
import GeneratedOn from "../utility_components/generated-on";
import ContextDocumentDialog from "./context_documents/context_document_dialog";
import {AnalysisTemplate, ContextDocument} from "./context_documents/types";
import Label from "../label";
import Iconify from "../iconify";
import {renderBarChart, renderLineChart, renderPieChart} from "./analysis_charting";
import {customScrollbarStyles} from "../../theme/css";
import ContextDocumentLabel from "./context_documents/context_document_label";
import Typewriter from "./typewriter";


interface Analysis {
  answers: {
    question: string;
    answer: string;
    sources: ContextDocument[];
    charts: {
      chart_type: string;
      chart_data: {
        labels: string[];
        values: number[];
      }
      chart_title: string;
      chart_data_scale: string;
    }[];
  }[];
  context_documents_ids: string[];
  template_id: string;
  date: string;
}


export default function CustomAnalysisList({
                                             ticker,
                                             analyses,
                                             documentsAvailable,
                                             templatesAvailable,
                                             loading,
                                             setSearchString,
                                             hasAnimated,
                                             setHasAnimated,
                                           }: {
  ticker: string,
  analyses: Analysis[],
  documentsAvailable: ContextDocument[],
  templatesAvailable: AnalysisTemplate[],
  loading: boolean,
  setSearchString: (searchString: string) => void,
  hasAnimated: boolean,
  setHasAnimated: (hasAnimated: boolean) => void,
}) {

  const [currentOpenedDocument, setCurrentOpenedDocument] = React.useState<ContextDocument>(documentsAvailable[0]);
  const [currentOpenedDocumentScrollToId, setCurrentOpenedDocumentScrollToId] = React.useState<string>("");
  const [documentDialogOpen, setDocumentDialogOpen] = React.useState<boolean>(false);
  const [currentOpenedAnalysis, setCurrentOpenedAnalysis] = React.useState<Analysis | null>(null);
  const [analysisDialogOpen, setAnalysisDialogOpen] = React.useState<boolean>(false);
  const [sourcesExpanded, setSourcesExpanded] = React.useState<{ [key: string]: boolean }>({}); // Object to track expansion state
  const theme = useTheme();

  const onSourceClick = (src: any) => {
    const document = documentsAvailable.find((doc) => doc.type === src.metadata.document_type);
    if (!document) {
      return;
    }
    setCurrentOpenedDocument(document);
    setDocumentDialogOpen(true);
    setCurrentOpenedDocumentScrollToId(src.metadata.source_id);
  };

  const toggleSourcesExpanded = (key: string) => {
    setSourcesExpanded((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderSources = (sources: ContextDocument[], index: number) => {
    const key = `sources-${index}`; // Unique key for each analysis/question


    return (
      <Stack direction="column" spacing={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button onClick={() => toggleSourcesExpanded(key)}
                  endIcon={<Iconify icon={sourcesExpanded[key] ? "mdi:chevron-up" : "mdi:chevron-down"}/>}
          >
            Sources
          </Button>
        </Stack>
        <Collapse in={sourcesExpanded[key]}>
          <Stack direction="column" spacing={1}>
            {sources && sources.length > 0 && sources.map((src: any, sIndex: number) => (
              <Stack key={`source-${sIndex}`} direction="row" spacing={1}
                     onClick={() => onSourceClick(src)}
                     sx={{cursor: "pointer", "&:hover": {color: "primary.main"}}}>
                <Box sx={{width: "95px"}}>
                  <ContextDocumentLabel type={src.metadata.document_type}/>
                </Box>
                <Typography variant="body2"
                            sx={{color: theme.palette.grey[600], borderLeft: "2px solid lightgrey", paddingLeft: 1}}>
                  &quot;{src?.page_content?.slice(0, 100)}...&quot;
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  const renderChart = (chart: any) => {
    if (!chart) {
      return null;
    }

    if (chart.chart_type === "line") {
      return renderLineChart(chart);
    }

    if (chart.chart_type === "bar") {
      return renderBarChart(chart);
    }
    if (chart.chart_type === "pie") {
      return renderPieChart(chart);
    }
    return null;
  };

  const renderAnalysesList = () => {
    if (loading) {
      return <CircularProgress/>;
    }
    if (analyses.length <= 0 || !analyses) {
      return <Typography variant="body1">No analyses available</Typography>;
    }

    // Sort by most recent on top; date is in the format "2024-11-03T15:15:14.665000"
    return (
      <Box className="existing-analyses" sx={{width: '100%'}}>
        <Stack spacing={1} sx={{width: '100%', maxHeight: 300, overflowY: 'auto', ...customScrollbarStyles(theme)}}>
          {analyses.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((analysis, index) =>
            <Stack direction="row"
                   key={`analysis-${index}`}
                   spacing={5}
                   justifyContent="space-between"
                   onClick={() => {
                     setCurrentOpenedAnalysis(analysis);
                     setAnalysisDialogOpen(true);
                   }}
                   sx={{
                     "&:hover": {
                       cursor: "pointer",
                       backgroundColor: theme.palette.action.hover,
                     }
                   }}>
              <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">
                    <strong>
                      {ticker} |{' '}
                      {index === 0 && !hasAnimated ? (
                        <Typewriter
                          text={`${templatesAvailable.find((template) => template.id === analysis.template_id)?.title || "Unknown"}`}
                          speed={50} onComplete={() => setHasAnimated(true)}/>
                      ) : (
                        <>{templatesAvailable.find((template) => template.id === analysis.template_id)?.title || "Unknown"}</>
                      )}
                    </strong>
                  </Typography>

                  {/*

<Typography variant="body1">
                    <strong>
                      {ticker} | {templatesAvailable.find((template) => template.id === analysis.template_id)?.title || "Unknown"}
                    </strong>
                  </Typography>
                  */}

                  {/* context documents labels */}
                  <Stack direction="row" spacing={1}>
                    {analysis.context_documents_ids && analysis.context_documents_ids.map((docId) => {
                      const doc = documentsAvailable.find((d) => d.id === docId);
                      if (!doc) {
                        return null;
                      }
                      return (
                        <Box sx={{maxWidth: "95px"}}>
                          <ContextDocumentLabel key={doc.id} type={doc.type}/>
                        </Box>
                      );
                    })}
                  </Stack>
                </Stack>
                <GeneratedOn dateToDisplay={analysis.date} textVariant="caption" textPrefix="" sx={{mt: 0}}/>
              </Stack>

              <Stack direction="row" spacing={1} alignItems="center">
                <Iconify icon="mdi:chevron-right" width={24}/>
              </Stack>


            </Stack>)}
        </Stack>
        <Dialog open={analysisDialogOpen}
                maxWidth="lg"
                fullWidth
                onClose={() => setAnalysisDialogOpen(false)}
        >
          <DialogTitle sx={{marginTop: 2}}>Analysis</DialogTitle>
          <DialogContent>
            {currentOpenedAnalysis && currentOpenedAnalysis.answers.map((answer, aIndex) => (
              <Box key={`answer-${aIndex}`} sx={{mt: 3}}>
                <Typography variant="h6">{aIndex + 1}. {answer.question}</Typography>
                {/* @ts-ignore */}
                <ReactMarkdown>{answer.answer}</ReactMarkdown>

                {/* List charts below */}
                <Box sx={{my: 3}}>
                  {answer.charts && answer.charts.length > 0 && answer.charts.map((chart, cIndex) => (
                    <Box key={`chart-${cIndex}`}>
                      {renderChart(chart)}
                    </Box>
                  ))}
                </Box>

                {/* List sources below */}
                {renderSources(answer.sources, aIndex)}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
              <Button onClick={() => setAnalysisDialogOpen(false)}>Cancel</Button>
            </Box>
          </DialogActions>
        </Dialog>

      </Box>
    );
  };

  return (
    <Stack className="custom-analysis" direction="column" sx={{width: "100%"}}>
      <Stack direction="column" spacing={2} sx={{maxWidth: "1000px"}}>
        <Stack direction="column" spacing={2}>
          {renderAnalysesList()}
          <ContextDocumentDialog
            content={currentOpenedDocument?.type === "PRESS-RELEASE" ? currentOpenedDocument?.annotatedContent.toString() : currentOpenedDocument?.annotatedContent}
            url={currentOpenedDocument?.url}
            date={new Date(currentOpenedDocument?.documentDate || "").toLocaleDateString()}
            dialogOpen={documentDialogOpen}
            setDialogOpen={setDocumentDialogOpen}
            startElementId={currentOpenedDocumentScrollToId}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
