// @mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid'; // Grid version 1
import {Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
// hooks
import React, {useEffect, useState} from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";

import axios, {endpoints} from 'src/utils/axios';

// components
import ChatChat from './chatChat';
import ChatLeftSidebar from "../../components/chat/chat-left-sidebar/chat-left-sidebar";
import {Chat, ChatMany, newChatTemplate} from "./types";
import {Company} from "../company/types";
import Iconify from "../../components/iconify";
import {fDateTime, toUserTimezone} from "../../utils/format-time";
import {ContextDocument} from "../../components/custom_analysis/context_documents/types";
import {ScoredCompany2} from "../../components/company/types";
import {useCredits} from "../../providers/credits-provider";

type Props = {
  ticker: string;
  company: ScoredCompany2;
  contextDocuments: ContextDocument[];
  children: React.ReactNode;
  closeDialog?: () => void;
};

export default function ChatLayout({ticker, company, contextDocuments, children, closeDialog}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));



  const [selectedChat, setSelectedChat] = useState<Chat>({...newChatTemplate});
  const [chats, setChats] = useState<ChatMany[]>([]);
  const [companies, setCompanies] = useState<Map<number, Company>>(new Map());
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {refreshCredits, userCredits} = useCredits();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };


  useEffect(() => {
    const fetchChatAndCompanies = async () => {
      await getChats(); // Get the sorted chats
      // if (sortedChats.length > 0) {
      //   await getChat(sortedChats[0].id); // Use the ID of the first chat
      // }
      await refreshCredits();
      // await getCompanies();
    };

    fetchChatAndCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChat = async (chatId: string) => {
    if (!chatId) {
      return;
    }
    setChatLoading(true);
    try {
      const result = await axios.get<Chat>(`${endpoints.chatNew}/${chatId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("Result", result.data);
      setSelectedChat(result.data);
    } finally {
      setChatLoading(false);
    }
  };

  const getChats = async () => {
    const result = await axios.get<any[]>(`${endpoints.chatNew}?ticker=${ticker}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // sort chats by the most recent updated_at
    const sortedChats = result.data.sort((a, b) => {
      const updatedA = a.updated_at ?? a.created_at ?? new Date(0).toISOString(); // Fallback to epoch time if both are null
      const updatedB = b.updated_at ?? b.created_at ?? new Date(0).toISOString(); // Fallback to epoch time if both are null

      return new Date(updatedB).getTime() - new Date(updatedA).getTime();
    });

    setChats(sortedChats);
    return sortedChats; // Return the sorted chats
  };

  const handleRefreshCredits = async () => {
    await refreshCredits();
  };

  const chatCreated = async () => {
    const sortedChats = await getChats(); // Get the sorted chats
    if (sortedChats.length > 0) {
      await getChat(sortedChats[0].id); // Use the ID of the first chat
    }
  };

  const refreshChatAndCredits = async (chatId: string) => {
    await getChat(chatId);
    await handleRefreshCredits();
  }


  // set the selected chat to the newChat when the dialog is closed
  const handleCloseDialog = () => {
    setSelectedChat({...newChatTemplate});
    if (closeDialog) {
      closeDialog();
    }
  }

  const renderLayoutDesktop = () => (
    <Grid className="chatLayout" container spacing={0}
      // Full viewport height for the entire desktop layout
          sx={{
            height: '100vh', overflow: 'hidden'
          }}>
      <Grid item xs={2} sx={{
        height: '100%',
        overflowY: 'auto'
      }}
      >
        <ChatLeftSidebar chats={chats}
                         company={company}
                         companies={companies}
                         closeDialog={handleCloseDialog}
                         chatCreated={chatCreated}
                         getChat={getChat} selectedChat={selectedChat}
                         setSelectedChat={setSelectedChat}
        />
      </Grid>


      <Grid item xs={10} sx={{display: 'flex', flexDirection: 'column', height: '100%'}}

      >
        <ChatChat ticker={ticker} chat={selectedChat}
                  company={company}
                  setSelectedChat={setSelectedChat}
                  contextDocuments={contextDocuments}
                  refreshChats={getChats} chatLoading={chatLoading}/>
      </Grid>
    </Grid>
  );

  const renderLayoutMobile = () => (
    <Stack className="mobile-nav" direction="column" sx={{height: '100dvh'}}>

      {/* Mobile Header */}
      <Stack direction="row" alignItems="center" justifyContent="space-between"
             sx={{px: 1, height: "48px", flexShrink: 0}}>
        <Button onClick={handleDrawerToggle}>
          <Iconify icon="material-symbols:menu-rounded" width={24} height={24}/>
        </Button>
        {selectedChat.ticker ?
          <Typography variant="body2">
            {selectedChat.ticker} | {fDateTime(toUserTimezone(selectedChat.updated_at))}
          </Typography> :
          <Typography variant="body2">
            New Chat
          </Typography>
        }
        {/* use empty box to center the header */}
        {closeDialog ? (
          <Button onClick={handleCloseDialog}>
            <Iconify icon="material-symbols:close" width={24} height={24}/>
          </Button>
        ) : <Box sx={{width: 64}}/>}
      </Stack>

      {/* Left Sidebar */}
      <Drawer
        open={drawerOpen}
        onClose={handleDrawerToggle}
        anchor="left"
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw", // Take 80% of the screen width
            maxWidth: "360px", // Optional: Limit maximum width
          },
        }}
        ModalProps={{
          container: document.getElementById('dialog-content-container'),
          style: {position: 'absolute'},
        }}
        PaperProps={{
          style: {position: 'absolute'},
        }}
        BackdropProps={{
          style: {position: 'absolute'},
        }}
      >
        <ChatLeftSidebar
          chats={chats}
          company={company}
          companies={companies}
          closeDialog={handleCloseDialog}
          chatCreated={() => {
            chatCreated();
            handleDrawerToggle();
          }}
          getChat={getChat}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
        />
      </Drawer>

      <Box sx={{flexGrow: 1, overflow: 'hidden'}}>
        <ChatChat
          ticker={ticker}
          chat={selectedChat}
          company={company}
          setSelectedChat={setSelectedChat}
          contextDocuments={contextDocuments}
          refreshChats={getChats}
          chatLoading={chatLoading}
        />
      </Box>
    </Stack>
  );

  return (
    <>
      {isMobile ? renderLayoutMobile() : renderLayoutDesktop()}
    </>
  );

}
