// React
import React, {useEffect, useState} from "react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Slider from '@mui/material/Slider';
import Typography from "@mui/material/Typography";
import {ListItemText, Snackbar} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate} from "react-router-dom";
import Alert from "@mui/material/Alert";

// components
import StyledProgressBar from "../../components/progress-bar/styles";
import Iconify from "../../components/iconify";
import ScoreRadarStyled from "../../components/company/score-radar-styled";
import axios, {endpoints} from "../../utils/axios";
import useDebounce from "../../hooks/use-debounce";
import CompanyTable from "../company/companyTable";
import {scrollToElement} from "../../components/company/utils";
import Survey from "../../components/survey/survey";
import {useResponsive} from "../../hooks/use-responsive";
import {gaTimingEvent, usePageTracking} from "../../utils/ga";
import {useAuthContext} from "../../auth/hooks";
import {sectorsWithIcons} from "../features/constants";
import LoginRegisterDialog from "../credits/login-register-dialog";
import {enlargeOnHover} from "../../common/styles";


export default function ScreenerLayout() {
  usePageTracking()


  const {user} = useAuthContext();

  // read screener settings from localStorage
  const storedSettings = JSON.parse(localStorage.getItem('screenerSettings') || '{}');
  const [valuation, setValuation] = React.useState(storedSettings.valuation || 2.5);
  const [financialStrength, setFinancialStrength] = React.useState(storedSettings.financialStrength || 2.5);
  const [futureGrowth, setFutureGrowth] = React.useState(storedSettings.futureGrowth || 2.5);
  const [efficiency, setEfficiency] = React.useState(storedSettings.efficiency || 2.5);
  const [selectedSectors, setSelectedSectors] = React.useState<boolean[]>(storedSettings.selectedSectors || sectorsWithIcons.map(() => true));
  const [showProgress, setShowProgress] = React.useState(true)
  const [showLoginDialog, setShowLoginDialog] = React.useState(false);

  const [resultCompanies, setResultCompanies] = React.useState<any[]>([])
  const navigate = useNavigate();

  const debouncedValuation = useDebounce(valuation, 800);
  const debouncedFinancialStrength = useDebounce(financialStrength, 800);
  const debouncedFutureGrowth = useDebounce(futureGrowth, 800);
  const debouncedEfficiency = useDebounce(efficiency, 800);

  const {authenticated} = useAuthContext();


  // track time spent
  useEffect(() => {
    const startTime = Date.now(); // Record start time

    return () => {
      const endTime = Date.now(); // Record end time
      const timeSpentMs = endTime - startTime; // Calculate time spent in milliseconds
      const timeSpentSec = Math.round(timeSpentMs / 1000); // Convert to seconds

      gaTimingEvent("Screener", timeSpentSec)
      console.log(`Time spent on Screener: ${timeSpentSec} seconds`); // Log time spent to console
    };
  }, []);

  // save screener settings to localStorage
  useEffect(() => {
    const screenerSettings = {
      valuation,
      financialStrength,
      futureGrowth,
      efficiency,
      selectedSectors
    };
    localStorage.setItem('screenerSettings', JSON.stringify(screenerSettings));
  }, [valuation, financialStrength, futureGrowth, efficiency, selectedSectors]);


  const handleSectorToggle = (index: number) => {
    const updatedSelections = [...selectedSectors];
    updatedSelections[index] = !updatedSelections[index];
    setSelectedSectors(updatedSelections);
  };

  const handleCompanyClick = (ticker: string, newTab: boolean) => {
    if (newTab) {
      window.open(`/companies/${ticker}`, '_blank');
    } else {
      navigate(`/companies/${ticker}`);
    }
  }

  const sliders = [
    {
      label: 'Valuation',
      value: valuation,
      setter: setValuation,
      marks: [
        {
          value: 0,
          label: 'Overvalued'
        },
        {
          value: 5,
          label: 'Undervalued'
        },
      ],
    },
    {
      label: 'Financial Strength',
      value: financialStrength,
      setter: setFinancialStrength,
      marks: [
        {
          value: 0,
          label: 'Financially Weak'
        },
        {
          value: 5,
          label: 'Financially Strong'
        },
      ]
    },
    {
      label: 'Efficiency',
      value: efficiency,
      setter: setEfficiency,
      marks: [
        {
          value: 0,
          label: 'Inefficient'
        },
        {
          value: 5,
          label: 'Efficient'
        },
      ],
    },
    {
      label: 'Future Growth',
      value: futureGrowth,
      setter: setFutureGrowth,
      marks: [
        {
          value: 0,
          label: 'Likely To Decline'
        },
        {
          value: 5,
          label: 'Likely To Grow'
        },
      ]
    },
  ]

  const [afterRegister, setAfterRegister] = useState(user?.is_new && !localStorage.getItem('afterRegisterMessageShown'));
  const handleAfterRegisterClose = () => {
    setAfterRegister(false);
    localStorage.setItem('afterRegisterMessageShown', 'true');
  }

  const renderSnackbar = (
    <Snackbar open={afterRegister} autoHideDuration={10000} onClose={handleAfterRegisterClose}>
      <Alert onClose={handleAfterRegisterClose} severity="success" sx={{width: '100%'}}>
        You have registered successfully! Welcome to ValueHunter!
      </Alert>
    </Snackbar>
  )

  useEffect(() => {
    // get companies for selected sectors and sliders
    const getCompanies = async () => {
      const slidersQuery = `valuation_gt=${debouncedValuation}&financial_strength_gt=${debouncedFinancialStrength}&growth_gt=${debouncedFutureGrowth}&efficiency_gt=${debouncedEfficiency}`;
      // build list of selected sectors in the format ?&sectors=Technology&sectors=Healthcare
      const sectorNames = sectorsWithIcons.filter((_, index) => selectedSectors[index]).map(sector => sector.title).join('&sectors=');
      setShowProgress(true);
      try {
        const result = await axios.get<any>(`${endpoints.screener.get}?${slidersQuery}&sectors=${sectorNames}`);
        setResultCompanies(result.data);
      } catch (error) {
        console.error("Error fetching companies in screener:", error);
      } finally {
        setShowProgress(false);
        setShowLoginDialog(true);
      }
    }
    getCompanies();
  }, [selectedSectors, debouncedValuation, debouncedFinancialStrength, debouncedFutureGrowth, debouncedEfficiency]);

  return (

    <>
      {showProgress && <StyledProgressBar/>}
      <Box
        sx={{margin: '0 auto', width: '100%', maxWidth: {xs: '100%', md: '90%', lg: '80%', xl: '70%'}}}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}
               sx={{m: 1}}>
          <SectorSidePanel selectedSectors={selectedSectors} handleToggle={handleSectorToggle}/>
          <FilterConfigurator sliders={sliders} resultCompaniesCount={resultCompanies.length}
                              isLoading={showProgress}/>
        </Stack>
        <Stack direction="column" alignItems="center" justifyContent="center"
               sx={{m: 2}}>
          <CompanyTable companies={resultCompanies} onCompanyClick={handleCompanyClick}/>
        </Stack>
      </Box>
      <Survey/>
      {renderSnackbar}
      <LoginRegisterDialog
        dialogOpen={!authenticated && showLoginDialog}
        onClose={() => {
          setShowLoginDialog(false);
        }}
      />
    </>

  );
}


// ----------------------------------------------------------------------

const SectorSidePanel: React.FC<{
  selectedSectors: boolean[],
  handleToggle: (index: number) => void
}> = ({selectedSectors, handleToggle}) => {
  const getBoxStyles = (index: number, length: number) => {
    const styles: any = {
      width: '100%',
      height: "67px",
      cursor: 'pointer',
    };
    return styles;
  };
  const xsUp = useResponsive('up', 'xs');

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={0.5}
           sx={{bgcolor: 'background.neutral', width: '82px', height: '100%', borderRadius: 2}}>
      {sectorsWithIcons.map((sector, index) => (
        <Box
          key={sector.title}
          sx={getBoxStyles(index, sectorsWithIcons.length)}
          onClick={() => handleToggle(index)}
        >
          {/*
                    <Tooltip
            title={`${sector.title} is ${selectedSectors[index] ? 'included' : 'excluded'}`}
            enterTouchDelay={0}
            placement="right"
            TransitionComponent={Zoom}
            sx={{fontSize: 8}}
          >
          </Tooltip>
          */}

          <Stack direction="column" justifyContent="center" alignItems="center"
                 sx={{mt: 1, mx: 1, ...enlargeOnHover}}>
            <Box sx={{color: selectedSectors[index] ? 'grey.800' : 'grey.500'}}>
              <Iconify icon={sector.icon} width={24} height={24}/>
            </Box>
            {
              xsUp && (
                <Typography
                  align="center"
                  sx={{
                    typography: 'caption',
                    fontSize: 10,
                    color: selectedSectors[index] ? 'grey.800' : 'grey.500',
                  }}
                >
                  {sector.title}
                </Typography>

              )
            }

          </Stack>

        </Box>
      ))}
    </Stack>
  );
};

type FilterConfiguratorProps = {
  sliders: { label: string, value: number, setter: (value: number) => void, marks: any[] }[],
  resultCompaniesCount: number
  isLoading: boolean
};

function FilterConfigurator({sliders, resultCompaniesCount, isLoading}: FilterConfiguratorProps) {
  const chart = {
    series: [
      {name: 'Series 1', data: sliders.map(slider => slider.value)}
    ],
    categories: ['Valuation', ['Financial', 'Strength'], 'Future Growth', 'Efficiency'],
    options: {
      dataLabels: {
        enabled: true,
        formatter(w: number) {
          return `> ${w}`;
        }
      }
    }
  };
  // @ts-ignore
  return (
    <Paper
      sx={{
        borderRadius: 2,
        bgcolor: 'background.neutral',
        height: '100%',
        width: '100%',
      }}
    >
      <Stack
        spacing={2}
        sx={{
          px: 2,
          py: 2,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <ListItemText
            primary='Filter companies by score'
            secondary='Select sectors on the left and build your company profile'
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center"
               justifyContent="center">
          <ScoreRadarStyled chart={chart}/>
        </Stack>

        <Stack direction="column" justifyContent='center' alignItems='center'
               sx={{mx: {xs: 5, md: 10, lg: 10, xl: 10}}}>


          <LoadingButton
            variant="contained"
            onClick={() => scrollToElement({hash: "#company-table"})}
            sx={{mb: 2, width: {xs: '100%', md: "80%"}}}
            loading={isLoading}
            disabled={isLoading}
          >
            <Stack direction="row" alignItems="center">
              <Box>Show <strong>{resultCompaniesCount}</strong> companies</Box>
            </Stack>
          </LoadingButton>

          {sliders.map((slider) => (
            <Slider
              key={slider.label}
              value={slider.value}
              onChange={(event, newValue) => slider.setter(newValue as number)}
              min={0}
              step={0.1}
              max={5}
              aria-labelledby="input-slider"
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `greater than ${value}`}
              marks={slider.marks}
              sx={{height: 4}}
            />
          ))}

        </Stack>

      </Stack>
    </Paper>
  );
}
