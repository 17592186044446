import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";


import {Company} from "../../../layouts/company/types";


interface CompanyAutocompleteProps {
  companies: Map<number, Company>;
  onSelectedCompany: (companyId: number | null) => void; // New prop
}

interface CompanyAutocompleteType {
  id: number;
  ticker: string;
  company_name: string;
  label: string;
}

export default function ChatCompanyAutocomplete({
                                                    companies,
                                                    onSelectedCompany
                                                  }: CompanyAutocompleteProps) {
  const theme = useTheme();
  const companies_array: CompanyAutocompleteType[] = Array.from(companies.values()).map((c) => ({
    id: c.id,
    ticker: c.ticker,
    company_name: c.company_name,
    label: `${c.company_name} (${c.ticker})`,
  }));

  return (
    <Autocomplete
      id="company-autocomplete"
      sx={{width: 300, mt: 2}}
      options={companies_array.sort((a, b) => -b.label.localeCompare(a.label))}
      groupBy={(option) => option.label[0]}
      onChange={(_, newValue) => {
        onSelectedCompany(newValue ? newValue.id : null); // Call the function when the selection changes
      }}
      autoHighlight
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
          {/*

          <Box component="span" sx={{
            backgroundColor: theme.palette.grey[200],
            marginRight: 1,
            padding: '0.5em',
            borderRadius: '0.3em'
          }}>
            {option.type}
          </Box>
          */}
          {option.label}
        </Box>

      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a company"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
}
