import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { m } from 'framer-motion';

const SignalScaleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 10,
  width: 270,
  position: 'relative',
}));

const ScaleBar = styled(Box)(() => ({
  width: '100%',
  height: 10,
  background: 'linear-gradient(to right, #F44336, #FF5722, #FF9800, #FFC107, #8BC34A, #4CAF50)',
  borderRadius: 5,
  position: 'relative',
  marginTop: 20,
}));

const ArrowWrapper = styled(m.div)(({ leftPosition }: { leftPosition: number }) => ({
  position: 'absolute',
  top: -5,
  left: `calc(${leftPosition}% - 12px)`, // Adjusting for center alignment of arrow
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const marks = [
  { value: 0, label: 'Strong Sell', color: '#F44336', customStyle: { marginLeft: '-10px' } }, // Move label to the left
  { value: 25, label: 'Sell', color: '#FF9800', customStyle: {} },
  { value: 50, label: 'Hold', color: '#FFC107', customStyle: {} },
  { value: 75, label: 'Buy', color: '#8BC34A', customStyle: {} },
  { value: 100, label: 'Strong Buy', color: '#4CAF50', customStyle: { marginRight: '-10px' } }, // Move label to the right
];

function getSignalValue(signal: string) {
  switch (signal) {
    case 'strong sell':
      return 5;
    case 'sell':
      return 33;
    case 'hold':
      return 50;
    case 'buy':
      return 68;
    case 'strong buy':
      return 95;
    default:
      return 50;
  }
}

interface SignalIndicatorComponentProps {
  signal: 'strong buy' | 'buy' | 'hold' | 'sell' | 'strong sell';
}

export default function SignalIndicatorComponent({ signal }: SignalIndicatorComponentProps) {
  const signalValue = getSignalValue(signal);

  return (

      <SignalScaleWrapper>
            {/*
    <SignalScaleWrapper style={{filter: 'blur(4px)'}}>
    */}
        <ArrowWrapper
          leftPosition={signalValue}
          initial={{ x: -10 }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 150 }}
        >
          <ArrowDropDownIcon fontSize="medium" style={{ color: '#333' }} />
        </ArrowWrapper>
      <ScaleBar />
      <Box display="flex" justifyContent="space-between" width="100%" mt={0.5}>
        {marks.map((mark, index) => (
            <Typography key={`mark-${index}`} variant="caption" color={mark.color} style={mark.customStyle}>
              {mark.label}
            </Typography>
        ))}
      </Box>
    </SignalScaleWrapper>
  );
}
