import React, {useEffect, useState} from "react";
import {Box, Card, CardHeader, Grid, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import axios, {endpoints} from "../../utils/axios";
import CompanyFinancialStrengthHistoricalChart, {
  HistoricalFinancialStabilityData
} from "./company-financial-strength-historical-chart";
import CompanyFinancialStrengthAiAnalysis from "./company-financial-strength-ai-analysis";
import ScoreProgress from "../utility_components/score-progress";
import Iconify from "../iconify";
import GeneratedOn from "../utility_components/generated-on";


interface CompanyFinancialStabilityProps {
  ticker: string | undefined;
  setAiAnalysesReady?: (key: string) => void | undefined;
}


interface FinancialStabilityData {
  overall_score: number,
  historical_ratios: HistoricalFinancialStabilityData,
  latest_ratios: {
    debtEquityRatio: { value: number, score: number },
    debtAssetsRatio: { value: number, score: number },
    currentRatio: { value: number, score: number },
    quickRatio: { value: number, score: number },
    interestCoverage: { value: number, score: number }
    piotroskiScore: { value: number, score: number }
  }
}

interface RatioWithScoringType {
  label: string,
  ratios:
    {
      label: string,
      value: number,
      score: number
    }[]
}


export default function CompanyFinancialStrength({ticker, setAiAnalysesReady}: CompanyFinancialStabilityProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [isLoadingAnalysisLatest, setIsLoadingAnalysisLatest] = useState(false);
  const [analysisDatetimeGenerated, setAnalysisDatetimeGenerated] = useState<string>('');

  const [financialStrengthAnalysis, setFinancialStrengthAnalysis] = useState<any>({});
  const [latestRatioData, setLatestRatioData] = useState<RatioWithScoringType[]>([])

  const [financialStabilityData, setFinancialData] = useState<FinancialStabilityData>({
    overall_score: 0,
    historical_ratios: {
      debtEquityRatio: {mean: 0, historical: []},
      currentRatio: {mean: 0, historical: []},
      quickRatio: {mean: 0, historical: []},
      interestCoverage: {mean: 0, historical: []}
    },
    latest_ratios: {
      debtEquityRatio: {value: 0, score: 0},
      debtAssetsRatio: {value: 0, score: 0},
      currentRatio: {value: 0, score: 0},
      quickRatio: {value: 0, score: 0},
      interestCoverage: {value: 0, score: 0},
      piotroskiScore: {value: 0, score: 0}
    }
  });


  useEffect(() => {
    const getCompanyFinancialStability = async () => {
      try {
        setIsLoading(true);
        const resp = await axios.get<any>(`/api/companies/financial-strength/${ticker}`);
        setFinancialData(resp.data);
        setLatestRatioData(
          [
            {
              label: "Liquidity",
              ratios: [
                {
                  label: "Current Ratio",
                  value: resp.data.latest_ratios.currentRatio.value,
                  score: resp.data.latest_ratios.currentRatio.score
                },
                {
                  label: "Quick Ratio",
                  value: resp.data.latest_ratios.quickRatio.value,
                  score: resp.data.latest_ratios.quickRatio.score
                }
              ]
            },
            {
              label: "Leverage",
              ratios: [
                {
                  label: "Debt To Equity",
                  value: resp.data.latest_ratios.debtEquityRatio.value,
                  score: resp.data.latest_ratios.debtEquityRatio.score
                },
                {
                  label: "Debt To Assets",
                  value: resp.data.latest_ratios.debtAssetsRatio.value,
                  score: resp.data.latest_ratios.debtAssetsRatio.score
                },
                {
                  label: "Interest Coverage Ratio",
                  value: resp.data.latest_ratios.interestCoverage.value,
                  score: resp.data.latest_ratios.interestCoverage.score
                }
              ]
            },
            {
              label: "Financial Strength",
              ratios: [
                {
                  label: "Piotroski F-Score",
                  value: resp.data.latest_ratios.piotroskiScore.value,
                  score: resp.data.latest_ratios.piotroskiScore.score
                }
              ]
            }
          ]
        );
      } catch (error) {
        console.error("Error fetching company valuation:", error);
      }
      setIsLoading(false);
    }

    const getCompanyFinancialStrengthAnalysis = async () => {
      const handleResponse = (response: any) => {
        setFinancialStrengthAnalysis(JSON.parse(response.data.answer));
        setAnalysisDatetimeGenerated(response.data.datetime_generated);
      }

      try {
        setIsLoadingAnalysis(true);
        const financialStrengthAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=financial_strength`);
        handleResponse(financialStrengthAnalysisResponse);
        setIsLoadingAnalysis(false);

        if (!financialStrengthAnalysisResponse.data.is_latest) {
          setIsLoadingAnalysisLatest(true);
          const latestFinancialStrengthAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=financial_strength&return_latest=true`);
          handleResponse(latestFinancialStrengthAnalysisResponse);
          setIsLoadingAnalysisLatest(false);
        }

      } catch (error) {
        console.error("Error fetching company valuation AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('financialStrength');
        }
      }
    }
    getCompanyFinancialStability();
    getCompanyFinancialStrengthAnalysis();
  }, [ticker, setAiAnalysesReady]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
        {isLoadingAnalysis && <Skeleton height={720}/>}
        {!isLoadingAnalysis && (
          <Stack direction="column" spacing={1}>
            <GeneratedOn dateToDisplay={analysisDatetimeGenerated} textVariant="caption" textPrefix="generated on"
                         regenerating={isLoadingAnalysisLatest}/>
            <CompanyFinancialStrengthAiAnalysis overallScore={financialStabilityData.overall_score}
                                                financialStrengthAnalysis={financialStrengthAnalysis}/>
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
        {isLoading && <Skeleton height={720}/>}
        {!isLoading && (
          <>
            <Card>
              <CardHeader title="Financial Strength Ratios"/>
              <Box sx={{m: 2}}>
                {latestRatioData.length > 0 && (
                  <>
                    <Box sx={{mt: 2}}>
                      <Box>
                        <Grid container spacing={1}>
                          <Grid item xs={4}/>
                          <Grid item xs={4}>
                            <Typography variant="body2"
                                        sx={{color: 'text.secondary', textAlign: "center"}}>
                              Value
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body2"
                                        sx={{color: 'text.secondary', textAlign: "center"}}>
                              Score
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      {latestRatioData.map((section, sectionIndex) => (
                        <Box sx={{mt: 2}} key={sectionIndex}>
                          <Divider textAlign="left"><strong>{section.label}</strong></Divider>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              {section.ratios.map((ratio, ratioIndex) => (
                                <Typography key={ratioIndex} variant="body2"
                                            sx={{color: 'text.secondary'}}>
                                  {ratio.label}
                                </Typography>
                              ))}
                            </Grid>
                            <Grid item xs={4}>
                              {section.ratios.map((ratio, ratioIndex) => (
                                <Typography key={ratioIndex} variant="body2" sx={{textAlign: "center"}}>
                                  {ratio.value}
                                </Typography>
                              ))}
                            </Grid>
                            <Grid item xs={4}>
                              {section.ratios.map((ratio, ratioIndex) => (
                                <Box key={ratioIndex} component="span">
                                  <ScoreProgress scoreValue={ratio.score}/>
                                </Box>
                              ))}
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              </Box>


            </Card>
            <Box sx={{mt: 2}}>
              {financialStabilityData.historical_ratios?.currentRatio?.historical ?
                <CompanyFinancialStrengthHistoricalChart
                  currentRatio={financialStabilityData.historical_ratios.currentRatio}
                  quickRatio={financialStabilityData.historical_ratios.quickRatio}
                  debtEquityRatio={financialStabilityData.historical_ratios.debtEquityRatio}
                  interestCoverage={financialStabilityData.historical_ratios.interestCoverage}
                /> :

                // don't show historical chart if data is not available
                <Stack sx={{my: 3, height: '100%'}} spacing={1} direction="column" alignItems="center"
                       justifyContent="center">
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Iconify width={28} icon='icon-park-twotone:attention' sx={{color: 'error.main'}}/>
                    <Typography variant="h6" sx={{color: 'text.primary'}}>
                      Historical Financial Strength
                    </Typography>
                  </Stack>
                  <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    Historical data is not available for this company.
                  </Typography>
                </Stack>
              }

            </Box>
          </>

        )}

      </Grid>
    </Grid>
  )
}
