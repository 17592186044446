import React, {Suspense, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {useTheme} from "@mui/material/styles";

import Iconify from "../../iconify";
import ChatLayout from "../../../layouts/chat";
import {LoadingScreen} from "../../loading-screen";
import {StaticDataProvider} from "../../../providers/static-data-provider";
import {ContextDocument} from "../../custom_analysis/context_documents/types";
import {ScoredCompany2} from "../../company/types";
import {useAuthContext} from "../../../auth/hooks";


const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export default function ChatTriggerButton({ticker, company, contextDocuments, contextDocumentsLoading}: { ticker: string, company: ScoredCompany2, contextDocuments: ContextDocument[], contextDocumentsLoading: boolean}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const {authenticated} = useAuthContext();

    const [showTooltip, setShowTooltip] = useState(Boolean(!authenticated)); // always show tooltip for unauthenticated users
    const theme = useTheme();

    useEffect(() => {
      if (contextDocuments && contextDocuments.length > 0 && !contextDocumentsLoading) {
        const interval = setInterval(() => {
          // Show tooltip
          if (!buttonClicked) {
            setShowTooltip(true);
          }
        }, 1000);

        return () => clearInterval(interval);
      }
      return () => {};
    }, [buttonClicked, contextDocuments, contextDocumentsLoading]);

    const openDialog = () => {
        setDialogOpen(true);
        setButtonClicked(true);
        setShowTooltip(false);
    }

    return (
        <>
            <Button
                aria-label="Open Chat"
                color="primary"
                variant="contained"
                // disable only for authenticated users with no context documents, for unauthenticated users, always enable
                disabled={authenticated && (!contextDocuments || contextDocuments.length === 0 || contextDocumentsLoading)}
                sx={{
                    width: 65,
                    height: 65,
                    borderRadius: "45%",
                    backgroundColor: "primary.main",
                    boxShadow: "0px 8px 14px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    bottom: "30px",
                    right: "30px",
                    zIndex: 1000,
                    transition: "transform 0.3s",
                    "&:hover": {
                        transform: "scale(1.1)",
                    },
                    "&:active": {
                        transform: "scale(0.95)",
                    },
                }}
                onClick={openDialog}
            >
              { authenticated && (!contextDocuments || contextDocuments.length === 0 || contextDocumentsLoading) ? <Iconify icon="eos-icons:bubble-loading" width="32px"/> :
                <Iconify icon="fluent:bot-sparkle-20-regular" width="32px"/>
              }
            </Button>
            {/* Tooltip (Ask ValueHunter AI...) */}
            <Grow in={showTooltip} timeout={500}>
                <Box
                    style={{
                        position: "fixed",
                        bottom: "120px", // Position above the button
                        right: "30px",
                        backgroundColor: theme.palette.primary.main,
                        boxShadow: "0px 8px 14px rgba(0, 0, 0, 0.2)",
                        opacity: 0.8,
                        color: "#fff",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        zIndex: 1001,
                        whiteSpace: "nowrap",
                    }}
                >
                    {/* Relative container for tooltip text & close icon */}
                    <Box sx={{position: "relative", pr: 4}}>
                        <Typography variant="body2">
                            Deep-dive into <strong>${ticker}</strong><br/>
                          with <strong>ValueHunter Copilot</strong>!
                        </Typography>

                    </Box>
                    <IconButton
                        size="small"
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#fff",
                        }}
                        onClick={() => setShowTooltip(false)}
                    >
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                </Box>
            </Grow>
            <Dialog
              fullScreen
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              TransitionComponent={Transition}
            >

              <Stack id="dialog-content-container" className="outer-stack" direction="column">
                <StaticDataProvider>
                  <ChatLayout
                    ticker={ticker}
                    company={company}
                    contextDocuments={contextDocuments}
                    closeDialog={() => setDialogOpen(false)} >
                    <Suspense fallback={<LoadingScreen/>}>
                      <Outlet/>
                    </Suspense>
                  </ChatLayout>
                </StaticDataProvider>
              </Stack>
            </Dialog>
        </>
    )
}
