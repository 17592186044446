export interface ChatMany {
  id: string;
  ticker: string;
  user_id: number;
  created_at: string;
  updated_at: string;
}

export interface Chat extends ChatMany {
  history: History[] | null;
  interactions: any[];
}

export const newChatTemplate: Chat = {
  id: "",
  ticker: "",
  user_id: 0,
  history: [],
  interactions: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
}

export interface History {
  type: string;
  content: string;
}

export interface UserCredits {
  credits: number;
  chat_credits: number;
  next_renewal: string;
}

export interface StaticData {
  highPriorityPredefinedPrompts: string[];
  allPredefinedPrompts: string[];
}
