import React, {useEffect, useState} from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

type SortOrder = "asc" | "desc";

export default function CustomTable({
                                      headers,
                                      rawData,
                                      renderRow,
                                      renderRowMobile,
                                      rowsPerPage = 10,
                                      initialSortConfig = {column: null, order: "asc"},
                                    }: {
  headers: { display: string; key: string }[];
  rawData: any[];
  renderRow: (row: any, index: number) => JSX.Element;
  renderRowMobile?: (row: any, index: number) => JSX.Element;
  rowsPerPage?: number;
  initialSortConfig?: { column: string | null; order: SortOrder };
}) {
  const [sortConfig, setSortConfig] = useState<{ column: string | null; order: SortOrder }>(initialSortConfig);
  const [page, setPage] = useState(0);
  const [sortedData, setSortedData] = useState(rawData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const handleSort = (column: string) => {
    const isAsc = sortConfig.column === column && sortConfig.order === "asc";
    const order: SortOrder = isAsc ? "desc" : "asc";
    setSortConfig({column, order});

    const sorted = [...sortedData].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue > bValue) return order === "asc" ? -1 : 1;
      if (aValue < bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
  };

  // Sort data on initial load
  useEffect(() => {
    if (initialSortConfig.column) {
      handleSort(initialSortConfig.column);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSortConfig]);

  const paginatedData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const renderPagination = () => {
    const totalPages = Math.ceil(sortedData.length / rowsPerPage);
    const pagesToDisplay = [];

    for (let i = 0; i < totalPages; i += 1) {
      if (i < 3 || i === totalPages - 1 || Math.abs(i - page) <= 1) {
        pagesToDisplay.push(i);
      } else if (i === 3 && page > 4) {
        pagesToDisplay.push("...");
      } else if (i > page && i === totalPages - 2 && page < totalPages - 3) {
        pagesToDisplay.push("...");
      }
    }

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton
          onClick={() => setPage((prev) => Math.max(0, prev - 1))}
          disabled={page === 0}
        >
          <KeyboardArrowLeft/>
        </IconButton>
        {pagesToDisplay.map((p, index) => (
          <Box
            key={index}
            mx={0.5}
            p={1}
            onClick={() => typeof p === "number" && setPage(p)}
            style={{
              cursor: typeof p === "number" ? "pointer" : "default",
              fontWeight: page === p ? "bold" : "normal",
            }}
          >
            {typeof p === "number" ? p + 1 : p}
          </Box>
        ))}
        <IconButton
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages - 1))}
          disabled={page === totalPages - 1}
        >
          <KeyboardArrowRight/>
        </IconButton>
      </Box>
    );
  };

  const renderDesktopTable = () => (
    <TableContainer
      component={Paper}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "900px",
      }}
    >
      <Table style={{flexGrow: 1}}>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.key} align="center">
                <TableSortLabel
                  active={sortConfig.column === header.key}
                  direction={sortConfig.column === header.key ? sortConfig.order : "asc"}
                  onClick={() => handleSort(header.key)}
                >
                  {header.display}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row: any, index: number) => renderRow(row, index))}
          {/* Add empty rows */}
          {paginatedData.length < rowsPerPage &&
            [...Array(rowsPerPage - paginatedData.length)].map((_, index) => (
              <TableRow key={`empty-row-${index}`} style={{height: 53}}>
                <TableCell colSpan={headers.length}/>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          mt: "auto",
          py: 2,
          backgroundColor: "white",
        }}
      >
        {renderPagination()}
      </Box>
    </TableContainer>
  );

  const renderMobileTable = () => {
    if (!renderRowMobile) {
      return null;
    }
    return (

      <Stack direction="column" spacing={1}>
        <Select value={sortConfig.column} onChange={(e) => handleSort(e.target.value as string)}>
          {headers.map((header) => (
            header.display && (
              <MenuItem key={header.key} value={header.key}>
                {header.display}
              </MenuItem>
            )
          ))}
        </Select>
        <Box>
          {paginatedData.map((row: any, idx: number) => renderRowMobile(row, idx))}
          {renderPagination()}
        </Box>
      </Stack>

    );
  }

  if (rawData.length === 0) {
    return (
      <Box>
        <Typography variant="h6" align="center">
          No data available
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {isMobile ? renderMobileTable() : renderDesktopTable()}
    </Box>
  );
}
