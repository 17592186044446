import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment-timezone";
import {Box, CircularProgress, Stack} from "@mui/material";
import Iconify from "../iconify";

interface GeneratedOnProps {
  dateToDisplay: string;
  timeAgo?: boolean;
  onlyDate?: boolean;
  textVariant?: "body1" | "body2" | "caption" | "overline" | "subtitle1" | "subtitle2";
  textPrefix?: string;
  regenerating?: boolean;
  sx?: object;
}

const GeneratedOn: React.FC<GeneratedOnProps> = ({
                                                   dateToDisplay,
                                                   timeAgo = false,
                                                   onlyDate = false,
                                                   textVariant = "body2",
                                                   textPrefix = "Generated on",
                                                   regenerating = false,
                                                   sx = {},
                                                 }) => {
  // Determine the user's local timezone
  const userTimezone = moment.tz.guess();

  if (!dateToDisplay) {
    return null;
  }

  // Parse the input date in UTC and convert it to the user's local timezone
  const dateMoment = moment.utc(dateToDisplay).tz(userTimezone);

  // Format the date for display
  const formattedDate = onlyDate ? dateMoment.format("D MMM YYYY") : dateMoment.format("D MMM YYYY, h:mm A");

  // Calculate the relative "time ago" string
  const timeAgoText = dateMoment.fromNow();

  const renderTextAndDate = () => (
    <Typography
      variant={textVariant}
      sx={{color: "text.secondary", ...sx}}
    >
      {textPrefix}{" "}
      {timeAgo ? (
        <Tooltip title={formattedDate} arrow>
          <strong>{timeAgoText}</strong>
        </Tooltip>
      ) : (
        <strong>{formattedDate}</strong>
      )}
    </Typography>
  );

  if (!regenerating) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        {renderTextAndDate()}
        <Box>
          <Iconify icon="material-symbols:check-box" width={15} height={15} sx={{color: "success.main"}}/>
        </Box>
      </Stack>)
  }
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{mt: 1}}>
      {renderTextAndDate()}
      <Stack direction="row">
        <Typography
          variant={textVariant}
          sx={{color: "text.secondary", ...sx}}
        >
          ...regenerating
        </Typography>
        <CircularProgress size={15} thickness={8} sx={{ml: 1}}/>
      </Stack>
    </Stack>
  )
};

export default GeneratedOn;
